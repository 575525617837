import { BL } from "../../Components/Utility";

const SentenceArray = [
  {
    sentence: (
      <>
        검열에 대해 말하자면, 저는 외부를 의식해서 검열하기보다는 내부(글 쓰는
        자아)를 의식해 검열하는 것이 더 중요하다고 생각하거든요. 내가 생각하는
        것을 속이지 않고, 쉽게 타협하지 않고, 끝까지 몰아세워 표현했는가. 제겐
        이 내부 검열이 더 중요했어요.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "쓰는 기분, 박연준",
      url: "https://product.kyobobook.co.kr/detail/S000000575067",
    },
  },
  {
    sentence: (
      <>
        시는 무한한 페이지로 만든 유일한 사전이며 한 편의 시는 단 하나의 단어다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "쓰는 기분, 박연준",
      url: "https://product.kyobobook.co.kr/detail/S000000575067",
    },
  },
  {
    sentence: (
      <>
        인터넷이나 SNS에는 시간이 없다. 실시간만 있다. 침묵과 망설임, 고민과
        사유가 깃든 의견은 드물다. 넘쳐나는 말들의 나부낌과 가벼운 비방 속
        갈등의 양상만 드러날 뿐이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "쓰는 기분, 박연준",
      url: "https://product.kyobobook.co.kr/detail/S000000575067",
    },
  },
  {
    sentence: <>최선은 선의 적이다.</>,
    tags: ["고독"],
    origin: {
      type: "인용",
      name: "볼테르",
    },
  },
  {
    sentence: (
      <>
        죽은 나무를 봐야 해.<p></p>
        죽은 나무 속 아직 죽지 않은 숨을 봐야 해.<p></p>
        뿌리부터 꼭대기의 잎새 하나까지 꼼꼼히 죽기까지,<p></p>그 속에 '미처'
        살아있는, 작은 삶을 찾아야 해.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "쓰는 기분, 박연준",
      url: "https://product.kyobobook.co.kr/detail/S000000575067",
    },
  },
  {
    sentence: (
      <>
        세상의 모든 사람이 무언가를 쓴다면, 쓰면서 꾸준히 성장한다면! 그야말로
        유토피아다.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "쓰는 기분, 박연준",
      url: "https://product.kyobobook.co.kr/detail/S000000575067",
    },
  },
  {
    sentence: (
      <>
        고급 독자일수록 '보이지 않는 무언가'를 보려고 책을 읽는다. 보이지 않는
        것, 그것은 오직 고급 독자를 위한 것이다.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "쓰는 기분, 박연준",
      url: "https://product.kyobobook.co.kr/detail/S000000575067",
    },
  },
  {
    sentence: (
      <>
        아이들은 시인으로 태어납니다. 다만 자신이 시인이었다는 기억을 잊은
        사람과 잊지 않은 채 어른이 되는 사람이 있을 뿐이지요.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "쓰는 기분, 박연준",
      url: "https://product.kyobobook.co.kr/detail/S000000575067",
    },
  },
  {
    sentence: (
      <>
        개인 브랜딩이 잘돼 있는 사람들은 실제로 브랜딩을 위해서 뭘 안 한
        사람들인 것 같다. 대체로 브랜딩을 위해서 뭔가를 하려는 사람들은 브랜딩을
        못 한다.
      </>
    ),
    tags: ["고독", "출판전야"],
    origin: {
      type: "책",
      name: "인디펜던트 워커, 정혜윤 외 10명",
      url: "https://product.kyobobook.co.kr/detail/S000001944974",
    },
  },
  {
    sentence: (
      <>
        내가 이 일을 하는지에 대한 거창한 목표가 필요하다. 그래야 돈이 없어도,
        평가가 없어도 견딜 수 있다.
      </>
    ),
    tags: ["고독", "출판전야"],
    origin: {
      type: "책",
      name: "인디펜던트 워커, 정혜윤 외 10명",
      url: "https://product.kyobobook.co.kr/detail/S000001944974",
    },
  },
  {
    sentence: (
      <>
        여러분이 가장 좋아하는 책들은 아마도 여러분을 가장 신뢰해준 책들일
        겁니다.<p></p>만약 여러분이 독자를 신뢰한다면 어떨까요?<p></p>논리적
        핑계, 논리 전개에 대한 집착, 야금야금 증식하며 서로 물고 물리는 문장들과
        같은 불신의 장치들이 사라질 것입니다.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "짧게 잘 쓰는 법, 벌린 클링켄보그",
      url: "https://product.kyobobook.co.kr/detail/S000001938262",
    },
  },
  {
    sentence: (
      <>
        우리의 글에 온갖 논리를 가져다 채워넣고 독자가 스스로 좋은 사색을 한
        것처럼 착각하게 만듭니다.<p></p>
        평범한 독자라는 관념은 공허한 자만의 결과입니다. 평범함은 평범함을
        낳습니다. 독자의 한계를 예단하지 마세요. 여러분이 그 한계에 갇히게
        됩니다.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "짧게 잘 쓰는 법, 벌린 클링켄보그",
      url: "https://product.kyobobook.co.kr/detail/S000001938262",
    },
  },
  {
    sentence: (
      <>
        글의 전개가 실로 확연하다면 논리가 얼마나 정연하든 모호하든 간에 논리를
        위한 접속어는 필요 없습니다.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "짧게 잘 쓰는 법, 벌린 클링켄보그",
      url: "https://product.kyobobook.co.kr/detail/S000001938262",
    },
  },
  {
    sentence: (
      <>
        논리와 논증이라고 하는 것은 대개 근접하고 유사한 아이디어들의 연속에
        지나지 않습니다.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "짧게 잘 쓰는 법, 벌린 클링켄보그",
      url: "https://product.kyobobook.co.kr/detail/S000001938262",
    },
  },
  {
    sentence: (
      <>
        두세 문장 이상으로 멀리 내다보지 마세요.<p></p>두세 문장 이후를 계획하지
        마세요.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "짧게 잘 쓰는 법, 벌린 클링켄보그",
      url: "https://product.kyobobook.co.kr/detail/S000001938262",
    },
  },
  {
    sentence: (
      <>
        개요 짜기가 아니라 문장을 하나하나 찾아가는 과정에서 여러분의 주제와
        생각을 구현할 흥미로운 길을 찾아낼 확률이 더 높습니다.<p></p>개요와
        초고라는 표준 모델은 글쓰기가 갖는 사색의 공간을 갉아먹습니다.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "짧게 잘 쓰는 법, 벌린 클링켄보그",
      url: "https://product.kyobobook.co.kr/detail/S000001938262",
    },
  },
  {
    sentence: (
      <>
        여러분은 같은 단어를 계속 반복해 쓰지 않을 것입니다. 그런데 왜 똑같은
        문장 구조를 계속 반복합니까? 말하려고 하는 것에 대해서와 마찬가지로 문장
        형태에도 신경을 많이 써서 다양하게 생각하고 써보는 것이 좋습니다.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "짧게 잘 쓰는 법, 벌린 클링켄보그",
      url: "https://product.kyobobook.co.kr/detail/S000001938262",
    },
  },
  {
    sentence: (
      <>
        글쓰기는 의미가 드러나는 마지막의 '요점'으로 독자를 운반하는
        컨베이어벨트가 아닙니다. 잘 쓴 글은 한 문장 한 문장이 중요하고
        즐겁습니다.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "짧게 잘 쓰는 법, 벌린 클링켄보그",
      url: "https://product.kyobobook.co.kr/detail/S000001938262",
    },
  },
  {
    sentence: (
      <>
        지워지는 볼펜으로 뭔가를 쓸 때, 나는 사실 지울 생각을 거의 하지 않는다.
        하지만 지울 수 있다고 생각하는 것만으로도 조금 더 과감해진다.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "아무튼 SF게임, 김초엽",
      url: "https://product.kyobobook.co.kr/detail/S000213676518",
    },
  },
  {
    sentence: (
      <>
        대중 취향에 맞추어 갈 수밖에 없는 매체들과 달리 소설은 소설가 한 명이
        먹고살 수 있으면 어떻게든 작품이 나오게 되어 있다. 그렇기에 소설은
        눈치를 덜 본다.
        <BL></BL>
        소설은 덜 팔려도, 다양한 이야기가 시작될 수 있고 때로는 가장 개성 있고
        뾰족한 이야기가 나오기도 하는 곳이다.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "아무튼 SF게임, 김초엽",
      url: "https://product.kyobobook.co.kr/detail/S000213676518",
    },
  },
  {
    sentence: <>설레는 것은 당할 수도 없고 시킬 수도 없는 일이다.</>,
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "동사의 맛, 김정선",
      url: "https://product.kyobobook.co.kr/detail/S000001863128",
    },
  },
  {
    sentence: (
      <>인터뷰는 한 사람을 주인공으로 삼아 세계를 읽어내는 일이기 때문이다.</>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "글쓰기의 최전선, 은유",
      url: "https://product.kyobobook.co.kr/detail/S000200116111",
    },
  },
  {
    sentence: (
      <>
        관계란 기억의 교환이다. 다른 사람에게 평범한 기억밖에는 만들어줄 수 없는
        사람은 '그 사람'이 될 수 없으며, 자신의 기억을 갖지 못하는 인간은 다른
        사람의 기억 속으로 들어갈 수 없다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "인용",
      name: "황현산",
    },
  },
  {
    sentence: (
      <>첫 번째 판단을 버려라. 그것은 시대가 네 몸을 통해 판단한 것이다.</>
    ),
    tags: ["고독"],
    origin: {
      type: "인용",
      name: "니체",
    },
  },
  {
    sentence: (
      <>
        알아야만 하는 것을 제 것으로 만들고자 하는 호기심이 아니라 자기
        자신으로부터 벗어날 수 있게 해주는 호기심
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "글쓰기의 최전선, 은유",
      url: "https://product.kyobobook.co.kr/detail/S000200116111",
    },
  },
  {
    sentence: (
      <>
        글감의 고갈에 직면하는 이유는 삶 혹은 나에 대한 인식의 한계에서
        비롯한다. 어쩌면 글감의 빈곤은 존재의 빈곤이고, 존재의 빈곤은 존재의
        외면일지도 모른다. 글감의 광맥은 '나'에게 있고, '나'란 관계의
        앙상블이다.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "글쓰기의 최전선, 은유",
      url: "https://product.kyobobook.co.kr/detail/S000200116111",
    },
  },
  {
    sentence: (
      <>
        못 써도 쓰려고 노력하는 동안 나를 붙들고 늘어진 시간은 글을 쓴 것이나
        다름없다고.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "글쓰기의 최전선, 은유",
      url: "https://product.kyobobook.co.kr/detail/S000200116111",
    },
  },
  {
    sentence: (
      <>
        인간이 물질 세계는 탐사하면서 스스로에 대한 탐사는 하지 않으려 한다.
        인간은 자기 삶에서 단순함의 너른 빈터를 충분히 남겨두어야만 인간일 수
        있다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "인용",
      name: "조지 오웰",
    },
  },
  {
    sentence: (
      <>
        새들이 일정한 간격으로 전깃줄에 앉는 이유는 이륙할 때 서로의 날갯짓을
        방해하는 난기류를 일으키지 않도록 하기 위함이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "건축가의 공간 일, 조성익",
      url: "https://product.kyobobook.co.kr/detail/S000213326771",
    },
  },
  {
    sentence: (
      <>
        타이핑은 목표를 향해 빠르게 나아가는 글을 쓰는 데 유리하다면, 손글씨는
        생각과 생각 사이에 여백을 만들어 사색적인 문장을 만드는 데 유리하다.
        손은 목표한 문장들 사이에 낭만적 문장이 자리 잡을 수 있게 해준다.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "건축가의 공간 일, 조성익",
      url: "https://product.kyobobook.co.kr/detail/S000213326771",
    },
  },
  {
    sentence: (
      <>
        나의 작업은 고독과 고요 속에서 진행되지만, 결국 마지막에는 모두와
        공유하게 되는 것이니까.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "나로 존재하는 법, 헤르만 헤세",
      url: "https://product.kyobobook.co.kr/detail/S000212169903",
    },
  },
  {
    sentence: (
      <>
        만약 세계가 인간에 의해 개선되었다면, 인간에 의해 더 풍요롭고, 생동감
        넘치고, 즐겁고, 위험하고, 재미있어졌다면 그건 세계를 개선하려는 사람들에
        의해 그렇게 된 것이 아니라, 진정한 이기주의자들에 의해 그렇게 된 거라는
        것 말이에요. 나는 여러분 역시 그런 이기주의자들에게 속하기를 바랍니다.
        이들처럼 진지하고 진정으로 이기적인 사람들은 목표나 목적을 알지
        못합니다. 그 살아가면서 자기 자신이 되는 것으로 족합니다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "나로 존재하는 법, 헤르만 헤세",
      url: "https://product.kyobobook.co.kr/detail/S000212169903",
    },
  },
  {
    sentence: (
      <>
        여러분 자신이 되세요. 그러면 세상은 풍성하고 아름다워집니다! 여러분
        자신이 되지 않고 거짓말쟁이와 겁쟁이가 되면, 세상은 가련해지고 개선이
        필요한 곳으로 보이게 되는 것입니다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "나로 존재하는 법, 헤르만 헤세",
      url: "https://product.kyobobook.co.kr/detail/S000212169903",
    },
  },
  {
    sentence: (
      <>
        자신의 고독을 찾은 자, 채색하고 꾸민 고독이 아니라, 그 자신의 고독,
        그에게 정해진 유일한 고독을 발견한 자는 복이 있을지니!
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "나로 존재하는 법, 헤르만 헤세",
      url: "https://product.kyobobook.co.kr/detail/S000212169903",
    },
  },
  {
    sentence: <>고독은 운명이 인간을 그 자신에게로 인도하는 길입니다.</>,
    tags: ["고독"],
    origin: {
      type: "책",
      name: "나로 존재하는 법, 헤르만 헤세",
      url: "https://product.kyobobook.co.kr/detail/S000212169903",
    },
  },
  {
    sentence: (
      <>
        정신적인 사람, 지식인은 꼭 어떤 외적 필요가 미덕으로 몰아가지 않는다
        해도, 예술작품으로 상쇄시켜야 하는 내적 약함이 없다 해도, 스스로 안에서
        자원하여 이런 곤궁과 필요를 만들어낼 수 있음에 틀림없다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "나로 존재하는 법, 헤르만 헤세",
      url: "https://product.kyobobook.co.kr/detail/S000212169903",
    },
  },
  {
    sentence: (
      <>
        인간에게 존재하는 희망은, 세계와 다른 사람은 못 바꿔도 최소한 자기
        자신만은 어느 정도 변화시키고 개선시킬 수 있다는 것인 듯하다. 그리고
        자신을 개선하는 사람 덕분에 세상은 은밀히 구원된다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "나로 존재하는 법, 헤르만 헤세",
      url: "https://product.kyobobook.co.kr/detail/S000212169903",
    },
  },
  {
    sentence: (
      <>
        돈과 권력은 불신의 산물이기 때문이다. 자신의 가장 깊은 곳에 깃든 힘을
        불신하는 사람, 그 힘이 부족한 사람은 돈 같은 대체제로 이를 상쇄해야
        한다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "나로 존재하는 법, 헤르만 헤세",
      url: "https://product.kyobobook.co.kr/detail/S000212169903",
    },
  },
  {
    sentence: (
      <>
        우리는 대중이 느끼는 행복을 포기해야 하죠. 우리는 우리 자신을, 자신의
        재능과 자질과 특성을 명확히 알아야 하고, 이런 자질을 완성하고,
        자기다워지는 데 삶을 바쳐야 해요. 그렇게 하는 것이 동시에 인류에
        봉사하는 것이 된답니다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "나로 존재하는 법, 헤르만 헤세",
      url: "https://product.kyobobook.co.kr/detail/S000212169903",
    },
  },
  {
    sentence: (
      <>
        넌 그런 사람이야. 그러니 다른 사람들이 너와 다르다고 그들을 시기하거나
        경멸해서는 안 된단다. 네가 '옳은지'를 묻지 말고, 네 영혼과 그 영혼의
        필요를 네 몸처럼, 이름처럼, 태어난 집안처럼 받아들이렴. 주어진 것, 피할
        수 없는 그것을 긍정하고, 그 편이 되어주어야 해. 온 세상이 이해하지
        못한다 해도...
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "나로 존재하는 법, 헤르만 헤세",
      url: "https://product.kyobobook.co.kr/detail/S000212169903",
    },
  },
  {
    sentence: <>나는 내 랜턴을 던져버렸고, 이제 어둠을 볼 수 있다.</>,
    tags: ["고독"],
    origin: {
      type: "인용",
      name: "웬델 베리",
    },
  },
  {
    sentence: (
      <>
        아이디어를 형성하려면 프라이버시와 공유의 결합이 필요하다. 플랫폼의
        설득적 디자인이 우리의 생각을 지금 바로 공유해야 한다고 단정함으로써, 더
        나아가 우리의 생각을 공개적인 자리에서 정리해야 한다고 단정함으로써 사고
        과정 속의 맥락을 무너뜨리기 때문이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "아무것도 하지 않는 법, 제니 오델",
      url: "https://product.kyobobook.co.kr/detail/S000202707652",
    },
  },
  {
    sentence: (
      <>
        깊이 생각하고 충분히 논의하기 위해서는 그것을 배양할 공간(고독
        그리고/또는 명확한 맥락)뿐 아니라 시간도 필요하다는 것이 분명해진다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "아무것도 하지 않는 법, 제니 오델",
      url: "https://product.kyobobook.co.kr/detail/S000202707652",
    },
  },
  {
    sentence: (
      <>
        '곧장 나아가는', 기술적으로 훌륭한 알고리즘은 내가 좋아하는 것과 그
        이유에 대해 늘 안정적인 답안을 제공한다. 그리하여 하나의 이미지로 나를
        점점 파묻어버리는 것 같다.
        <BL></BL>만약 우리가 책을 읽는데 갈수록 내용이 앞과 비슷해져서 결국
        똑같은 내용을 반복해서 읽게 된다면 아마 그 책을 덮어버릴 것이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "아무것도 하지 않는 법, 제니 오델",
      url: "https://product.kyobobook.co.kr/detail/S000202707652",
    },
  },
  {
    sentence: (
      <>
        우리에게는 관심을 거두는 능력뿐 아니라 다른 곳에 관심을 기울이는 능력,
        관심을 확대하고 증식하는 능력, 관심을 더욱 예리하게 갈고닦는 능력이
        필요하다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "아무것도 하지 않는 법, 제니 오델",
      url: "https://product.kyobobook.co.kr/detail/S000202707652",
    },
  },
  {
    sentence: (
      <>
        우리는 두 가지를 다 할 수 있어야 한다. 사색하는 것과 참여하는 것, 떠나는
        것과 우리를 필요로 하는 곳으로 언제나 다시 돌아오는 것.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "아무것도 하지 않는 법, 제니 오델",
      url: "https://product.kyobobook.co.kr/detail/S000202707652",
    },
  },
  {
    sentence: (
      <>
        물러남과 사색은 지금 일어나는 일을 파악하는 데 반드시 필요하지만, 이
        시간은 언제나 이 세상에 대한 책임, 이 세상에서 져야 할 나의 책임을
        상기시킨다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "아무것도 하지 않는 법, 제니 오델",
      url: "https://product.kyobobook.co.kr/detail/S000202707652",
    },
  },
  {
    sentence: (
      <>
        나는 훨씬 검소하게 영원히 살 수 있는 방법을 공손히 제안한다. 그 방법은
        짧은 한순간이 무한하게 펼쳐질 수 있또록, 무언가를 생산하는 시간의
        궤도에서 벗어나는 것이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "아무것도 하지 않는 법, 제니 오델",
      url: "https://product.kyobobook.co.kr/detail/S000202707652",
    },
  },
  {
    sentence: (
      <>가장 긴 삶은 시간 가는 줄 모르는 즐거움을 가장 많이 느낀 삶이다.</>
    ),
    tags: ["고독"],
    origin: {
      type: "인용",
      name: "존 뮤어",
    },
  },
  {
    sentence: (
      <>
        '아무것도 하지 않을' 시간과 장소를 마련하는 것이 가장 중요한데, 이러한
        시간과 장소가 없으면 개인적으로나 집단적으로나 생각하고, 성찰하고,
        치유하고, 자신을 지탱할 방법이 없기 때문이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "아무것도 하지 않는 법, 제니 오델",
      url: "https://product.kyobobook.co.kr/detail/S000202707652",
    },
  },
  {
    sentence: (
      <>
        아무것도 말하지 않는 것은 무언가 말할 것을 만들어내기 이전 단계로
        기능한다. '아무것도 하지 않는 것'은 사치도, 시간 낭비도 아니다. 오히려
        의미 있는 생각과 발화의 필수 요소이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "아무것도 하지 않는 법, 제니 오델",
      url: "https://product.kyobobook.co.kr/detail/S000202707652",
    },
  },
  {
    sentence: (
      <>
        우리를 억압하는 세력은 자기표현을 막지 않습니다. 오히려 자신을
        표현하라고 강요합니다. 할 말이 없다는 것, 아무것도 말하지 않을 권리가
        있다는 것은 얼마나 다행입니까. 오로지 그때에만 말할 가치가 있는 극히
        드문 것들을 만들어낼 기회가 있습니다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "인용",
      name: "질 들뢰즈",
    },
  },
  {
    sentence: (
      <>
        고독과 관찰은, 사람들과 함께할 때 느끼는 단순한 즐거움은 그 자체로
        하나의 목적일 뿐 아니라 삶이라는 행운을 얻은 모든 사람이 가진 양도
        불가능한 권리로 여겨져야 한다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "아무것도 하지 않는 법, 제니 오델",
      url: "https://product.kyobobook.co.kr/detail/S000202707652",
    },
  },
  {
    sentence: (
      <>
        성공적인 책은 그 안에 있는 무언가 때문에 만들어지는 게 아니라, 빠진 것
        때문에 만들어진다.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "인용",
      name: "마크 트웨인",
    },
  },
  {
    sentence: (
      <>우리의 여행은 출발한 곳으로 돌아와, 그곳을 재발견할 때 끝난다.</>
    ),
    tags: ["창작"],
    origin: {
      type: "인용",
      name: "T.S 엘리엇",
    },
  },
  {
    sentence: (
      <>
        진정한 발견이란 새로운 땅을 찾아내는 것이 아니라 새로운 눈으로 보는
        것이다.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "인용",
      name: "마르셀 프루스트",
    },
  },
  {
    sentence: (
      <>
        '잘 쓰는 법'을 배우는 것은 '이야기 쓰는 법'을 배우는 것과 동의어가
        아니다. 잘 쓰는 것은 두 번째 문제다.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "끌리는 이야기는 어떻게 쓰는가, 리사 크론",
      url: "https://product.kyobobook.co.kr/detail/S000212170062",
    },
  },
  {
    sentence: <>가장 개인적인 것이 가장 창의적인 것이다.</>,
    tags: ["고독"],
    origin: {
      type: "인용",
      name: "마틴 스콜세지",
    },
  },
  {
    sentence: (
      <>
        아직 아무것도 적지 않은 종이의 흰색과 무언가를 썼다 지운 후의 흰색은
        같으면서 같지 않다. 말을 하기 전의 침묵과 말을 한 후의 침묵도 같은
        침묵이면서 같은 침묵이 아니다.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "멀고도 가까운, 리베카 솔닛",
      url: "https://product.kyobobook.co.kr/detail/S000001291143",
    },
  },
  {
    sentence: (
      <>
        가끔은 한 사람의 이야기가 더 큰 영역으로 들어가는 입구가 되어 주기도
        한다.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "멀고도 가까운, 리베카 솔닛",
      url: "https://product.kyobobook.co.kr/detail/S000001291143",
    },
  },
  {
    sentence: (
      <>
        창조는 언제나 어둠 속에서 일어난다. 무언가를 만들어 내는 일은 당신이
        무슨 일을 하고 있는지 정확히 모르고 있을 때만 일어난다. 창조는 그렇게
        어둠 속으로 들어감으로써, 빛 속에만 머물지 않음으로써 가능하기 때문이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "멀고도 가까운, 리베카 솔닛",
      url: "https://product.kyobobook.co.kr/detail/S000001291143",
    },
  },
  {
    sentence: (
      <>
        자아를 규정하는 것은 고통과 감각이다. 당신이 느낄 수 없는 것은 당신이
        아니다. 느껴지지 않는 것은 선뜻 돌봐 줄 수가 없다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "멀고도 가까운, 리베카 솔닛",
      url: "https://product.kyobobook.co.kr/detail/S000001291143",
    },
  },
  {
    sentence: (
      <>
        "너는 지금까지 한 번도 본 적이 없는 사람들에게 사랑받을 거야."<p></p>
        이것이 당신의 이야기에 푹 빠져들 낯선 이와 맺는 특별한 계약이며, 작가와
        글쓰기라는 행위를 이루는 고독에 대한 부분적인 보상이다.
      </>
    ),
    tags: ["글쓰기", "고독"],
    origin: {
      type: "책",
      name: "멀고도 가까운, 리베카 솔닛",
      url: "https://product.kyobobook.co.kr/detail/S000001291143",
    },
  },
  {
    sentence: (
      <>
        글쓰기는 누구에게도 할 수 없는 말을 아무에게도 하지 않으면서 동시에
        모두에게 하는 행위이다.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "멀고도 가까운, 리베카 솔닛",
      url: "https://product.kyobobook.co.kr/detail/S000001291143",
    },
  },
  {
    sentence: (
      <>
        지금까지도 놀라게 하는 것은 이야기의 숲과 고독 너머에 건너편이 있다는
        것, 그리고 그 건너편으로 나가면 사람들을 만날 수 있다는 사실이다. 작가는
        직업의 특성상 고립되며, 또 그래야 할 필요가 있다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "멀고도 가까운, 리베카 솔닛",
      url: "https://product.kyobobook.co.kr/detail/S000001291143",
    },
  },
  {
    sentence: (
      <>
        만드는 이가 된다는 것은, 다른 이를 위한 세상을 만드는 일, 그저 물질적
        세상뿐 아니라 그 물질적 세상을 지배하는 이념의 세계, 우리가 희망하고 그
        안에서 살아가는 꿈까지 만드는 것이다.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "멀고도 가까운, 리베카 솔닛",
      url: "https://product.kyobobook.co.kr/detail/S000001291143",
    },
  },
  {
    sentence: (
      <>
        작가가 홀로 들어가 자신이 마주친 미지의 영역을 기록으로 남긴 것이
        책이라는 신기한 삶이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "멀고도 가까운, 리베카 솔닛",
      url: "https://product.kyobobook.co.kr/detail/S000001291143",
    },
  },
  {
    sentence: (
      <>
        본인을 계속 지탱해 줄 장소를 찾기 시작해야 할 나이라고 이야기했다.
        장소가 사람보다 더 믿을 만하고, 가끔은 사람보다 더 오래 관계가
        유지되기도 한다고 말이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "멀고도 가까운, 리베카 솔닛",
      url: "https://product.kyobobook.co.kr/detail/S000001291143",
    },
  },
  {
    sentence: (
      <>
        "없앤 페이지들도 어떤 식으로든 작품 속에 남아있다."고 엘리 위젤은
        말했다. "200페이지를 쓰겠다고 마음먹고 쓴 200페이지짜리 책과, 원래는
        800페이지였던 책이 줄어서 200페이지짜리가 된 책은 차이가 있다.
        600페이지가 그 안에 남아 있다. 눈에 보이지 않을 뿐."
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "퇴고의 힘, 맷 벨",
      url: "https://product.kyobobook.co.kr/detail/S000202374908",
    },
  },
  {
    sentence: (
      <>
        저자의 즐거움은 저자를 이끄는 최고의 안내자다. 당신의 책이 독자에게 어떤
        즐거움을 줄지는 아직 모르지만, 당신에게 즐거움을 주는지부터 우선
        생각해보라.
      </>
    ),
    tags: ["글쓰기"],
    origin: {
      type: "책",
      name: "퇴고의 힘, 맷 벨",
      url: "https://product.kyobobook.co.kr/detail/S000202374908",
    },
  },
  {
    sentence: <>자신을 사랑하는 것이야말로 평생 지속되는 로맨스다.</>,
    tags: ["고독"],
    origin: {
      type: "인용",
      name: "장 자크 루소",
    },
  },
  {
    sentence: (
      <>
        당신이 더 나이 들었을 때 자신과의 만남을 즐길 수 있도록 자신을 기록하라.
      </>
    ),
    tags: ["창작", "고독"],
    origin: {
      type: "인용",
      name: "장 자크 루소",
    },
  },
  {
    sentence: (
      <>
        글을 쓸 때의 기분이나 글을 쓰고자 하는 감성적 욕구가 작문 시간에 배우는
        인지적인 기술보다 본질적으로 중요하다.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "인용",
      name: "앨리스 플래허티",
    },
  },
  {
    sentence: <>고독이란 생명의 요구다.</>,
    tags: ["고독"],
    origin: {
      type: "인용",
      name: "쇠렌 키에르케고르",
    },
  },
  {
    sentence: (
      <>
        우주적 몽상은 우리가 계속해서 연구하듯이 고독한 상태의 현상이며 몽상가의
        영혼 속에 뿌리내리고 있는 현상이다.<BL></BL>우주적 이마주는 혼에, 고독한
        혼에, 모든 고독의 원리인 혼에 속한 것임을 증명할 수 있다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "몽상의 시학, 가스통 바슐라르",
      url: "https://www.yes24.com/Product/Goods/2605001",
    },
  },
  {
    sentence: (
      <>
        나는 '침잠'이라는 말을 좋아한다. 물속 깊숙이 잠기면 무음의 세계를 떠도는
        듯한 고요함에 휘감긴다. 그런 고요함 속에서 혼자 무언가에 몰두했다고
        하자. 그렇게 만들어진 것은 영원히 사라지지 않는다. 물 위에 떠오른 뒤에도
        자기 안에 존재한다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "혼자 있는 시간의 힘, 사이토 다카시",
      url: "https://www.yes24.com/Product/Goods/19419272",
    },
  },
  {
    sentence: (
      <>
        처음부터 작품을 의미의 인질로 삼으면 잠재력이 제한된다. 예술은 예술가의
        계획보다 훨씬 더 강력하다.
      </>
    ),
    tags: ["창작", "영감", "동기부여"],
    origin: {
      type: "책",
      name: "창조적 행위:존재의 방식, 릭 루빈",
      url: "https://www.yes24.com/Product/Goods/119886818",
    },
  },
  {
    sentence: (
      <>
        번개에 집착하지 않고 번개를 둘러싼 공간에 집중하는 것이 더 건설적인
        전략이다. 올바른 사전 조건이 충족되지 않으면 번개가 치지 않고, 그 전기를
        포착해 사용하지 않으면 소멸해버리기 때문에 공간이 중요하다.
      </>
    ),
    tags: ["창작", "영감", "동기부여"],
    origin: {
      type: "책",
      name: "창조적 행위:존재의 방식, 릭 루빈",
      url: "https://www.yes24.com/Product/Goods/119886818",
    },
  },
  {
    sentence: (
      <>
        완벽이란 더 이상 보탤 것이 없는 상태가 아니다. 더 이상 뺄 것이 없는
        상태를 말한다.
      </>
    ),
    tags: ["작가", "창작"],
    origin: {
      type: "인용",
      name: "앙투안 드 생텍쥐페리",
    },
  },
  {
    sentence: (
      <>
        혁신적인이라는 말 자체가 맥락이 없다는 뜻이니까. 혁신은 스스로 맥락을
        만든다.
      </>
    ),
    tags: ["창작", "영감", "동기부여"],
    origin: {
      type: "책",
      name: "창조적 행위:존재의 방식, 릭 루빈",
      url: "https://www.yes24.com/Product/Goods/119886818",
    },
  },
  {
    sentence: (
      <>
        확실한 건 달라지리라는 것뿐이다. 당신도 마찬가지다. 몇 년 동안 공들인
        작품과 더 이상 부합하지 않는 모습으로 변했을 수도 있다. 당신을 선명히
        비추던 거울이 희미해져서 이제 그 작품은 거울이 아니라 당신의 옛날
        사진처럼 보이기 시작한다. 연결이 끊겨버린 작품을 완성하고 공유하는 것은
        맥 빠지는 일이다.
      </>
    ),
    tags: ["창작", "영감", "동기부여"],
    origin: {
      type: "책",
      name: "창조적 행위:존재의 방식, 릭 루빈",
      url: "https://www.yes24.com/Product/Goods/119886818",
    },
  },
  {
    sentence: (
      <>
        당연히 순차적으로 작업하고 싶겠지만 막힌 부분을 건너뛰고 다른 부분을
        먼저 끝낸 다음 돌아가라.<p></p>
        양쪽 지점에 무엇이 있는지 분명하게 알아야 두 지점을 잇는 다리를 만들기가
        쉬워진다.
      </>
    ),
    tags: ["창작", "영감", "동기부여"],
    origin: {
      type: "책",
      name: "창조적 행위:존재의 방식, 릭 루빈",
      url: "https://www.yes24.com/Product/Goods/119886818",
    },
  },
  {
    sentence: (
      <>
        기업은 분기별 수익과 생산 일정의 관점에서 생각한다. 예술가는 시대를
        초월한 탁월함의 관점에서 생각한다.
      </>
    ),
    tags: ["창작", "영감", "동기부여"],
    origin: {
      type: "책",
      name: "창조적 행위:존재의 방식, 릭 루빈",
      url: "https://www.yes24.com/Product/Goods/119886818",
    },
  },
  {
    sentence: (
      <>
        당신이 마주한 그 문제는 의식의 정면이 아닌 뒤쪽에서 가볍게 다루어야만
        해결될 수 있는 문제일지도 모른다. 문제를 마음에 품은 채로 전혀 관련 없는
        단순한 일을 하면서 시간을 보낸다.
      </>
    ),
    tags: ["창작", "영감", "동기부여"],
    origin: {
      type: "책",
      name: "창조적 행위:존재의 방식, 릭 루빈",
      url: "https://www.yes24.com/Product/Goods/119886818",
    },
  },
  {
    sentence: (
      <>
        오스카 와일드는 세상에는 너무 중요해서 오히려 너무 심각하게 받아들이면
        안 되는 것들이 있다고 했다. 예술도 거기 속한다. 시작이 부담스럽지 않도록
        기준을 낮추면 결과에 구애받지 않고 자유롭게 놀고 탐구하고 실험할 수
        있다.
      </>
    ),
    tags: ["창작", "영감", "동기부여"],
    origin: {
      type: "책",
      name: "창조적 행위:존재의 방식, 릭 루빈",
      url: "https://www.yes24.com/Product/Goods/119886818",
    },
  },
  {
    sentence: (
      <>
        우리는 예술가의 작품을 결과물로 생각하는 경향이 있다.<p></p>예술가의
        진짜 작품은 그가 세상에 존재하는 방식이다.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "창조적 행위:존재의 방식, 릭 루빈",
      url: "https://www.yes24.com/Product/Goods/119886818",
    },
  },
  {
    sentence: (
      <>
        예술을 만드는 것 자체가 아니라 예술을 만들 수밖에 없는 멋진 상태에
        놓이는 것을 목표로 삼아라.
      </>
    ),
    tags: ["작가", "창작"],
    origin: {
      type: "인용",
      name: "로버트 헨리",
    },
  },
  {
    sentence: (
      <>
        의학, 법학, 경영학, 공학 모두 중요해. 삶에 필수적이지. 하지만 시와 미,
        낭만, 사랑은 우리가 사는 이유야.
      </>
    ),
    tags: ["동기부여"],
    origin: {
      type: "영화",
      name: "죽은 시인의 사회",
      url: "https://search.naver.com/search.naver?sm=tab_hty.top&where=nexearch&ssc=tab.nx.all&query=%EC%98%81%ED%99%94+%EC%A3%BD%EC%9D%80+%EC%8B%9C%EC%9D%B8%EC%9D%98+%EC%82%AC%ED%9A%8C&oquery=%EC%98%81%ED%99%94+%EC%A3%BD%EC%9D%80+%EC%8B%9C%EC%9D%B8%EC%9D%98+%EC%82%AC%ED%9A%8C&tqi=iOhjNlqps8wssLrnREZssssstIN-326089",
    },
  },
  {
    sentence: (
      <>
        우리가 시를 읽고 쓰는 건 시가 보기 좋아서가 아니야. 시를 읽고 쓰는 건
        우리가 인류의 일원이기 때문이지.
      </>
    ),
    tags: ["동기부여"],
    origin: {
      type: "영화",
      name: "죽은 시인의 사회",
      url: "https://search.naver.com/search.naver?sm=tab_hty.top&where=nexearch&ssc=tab.nx.all&query=%EC%98%81%ED%99%94+%EC%A3%BD%EC%9D%80+%EC%8B%9C%EC%9D%B8%EC%9D%98+%EC%82%AC%ED%9A%8C&oquery=%EC%98%81%ED%99%94+%EC%A3%BD%EC%9D%80+%EC%8B%9C%EC%9D%B8%EC%9D%98+%EC%82%AC%ED%9A%8C&tqi=iOhjNlqps8wssLrnREZssssstIN-326089",
    },
  },
  {
    sentence: (
      <>
        단어의 맛과 언어의 맛을 즐겨라. 누가 뭐라고 하더라도 언어와 생각으로
        세상을 바꿀 수 있다.
      </>
    ),
    tags: ["동기부여"],
    origin: {
      type: "영화",
      name: "죽은 시인의 사회",
      url: "https://search.naver.com/search.naver?sm=tab_hty.top&where=nexearch&ssc=tab.nx.all&query=%EC%98%81%ED%99%94+%EC%A3%BD%EC%9D%80+%EC%8B%9C%EC%9D%B8%EC%9D%98+%EC%82%AC%ED%9A%8C&oquery=%EC%98%81%ED%99%94+%EC%A3%BD%EC%9D%80+%EC%8B%9C%EC%9D%B8%EC%9D%98+%EC%82%AC%ED%9A%8C&tqi=iOhjNlqps8wssLrnREZssssstIN-326089",
    },
  },
  {
    sentence: <>양이 질을 만들고, 노력이 쌓여 감각이 된다.</>,
    tags: ["창작"],
    origin: {
      type: "책",
      name: "에디토리얼 씽킹, 최혜진",
      url: "https://www.yes24.com/Product/Goods/124006476",
    },
  },
  {
    sentence: (
      <>
        글쓰기, 편집, 창작은 오류를 없애는 작업이 아니다. 다르게 해석할 여지가
        있음에도 한쪽 손을 들어주는 일, 입장을 밝히는 일, 오류를 품고 프레임을
        치는 일이다.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "에디토리얼 씽킹, 최혜진",
      url: "https://www.yes24.com/Product/Goods/124006476",
    },
  },
  {
    sentence: (
      <>
        컨셉 도출에 가장 필요한 역량은 재치가 아니라 끈기라 생각한다. 내가
        깃발을 꽂을 수 있는 빈 땅이 보일 때까지 물고 늘어지면서 끝까지
        자문자답하는 끈기가 기억되는 컨셉을 만든다.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "에디토리얼 씽킹, 최혜진",
      url: "https://www.yes24.com/Product/Goods/124006476",
    },
  },
  {
    sentence: (
      <>
        모든 창작물은 기존의 예술에 영향을 받는다. 거기에 자신의 독창성을 5~10%
        정도 가미한다면 훌륭하고 감사할 일이다.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "인용",
      name: "류이치 사카모토",
    },
  },
  {
    sentence: (
      <>
        언어는 질문하기 위해 창안되었다. 대답은 투덜대거나 제스처로 할 수 있지만
        질문은 반드시 말로 해야 한다. 사람이 사람다운 것은 첫 질문을 던졌을
        때부터였다. 사회적 정체는 답이 없어서가 아니라 질문을 할 충동이 없는
        데에서 비롯된다.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "인용",
      name: "에릭 호퍼",
    },
  },
  {
    sentence: (
      <>가치가 대상에 내재된 것이 아니라 보는 사람에게 달려 있는 셈이다.</>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "에디토리얼 씽킹, 최혜진",
      url: "https://www.yes24.com/Product/Goods/124006476",
    },
  },
  {
    sentence: (
      <>
        삶은 데이터의 축적이 아니라 편집 결과의 축적이니까.
        <BL></BL>
        살아온 모든 순간을 누락 없이 축적한 데이터가 있다고 해도 그것이 삶이 될
        수는 없다. 중요한 건 자기 서사고, 의미 부여다.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "에디토리얼 씽킹, 최혜진",
      url: "https://www.yes24.com/Product/Goods/124006476",
    },
  },
  {
    sentence: <>글쓰기란 결국 이리저리 산책을 하다가 우연히 얻은 부산물이다.</>,
    tags: ["고독", "산책"],
    origin: {
      type: "책",
      name: "걷기의 즐거움, 레슬리 스티븐 외 33명",
      url: "https://www.yes24.com/Product/Goods/122895044",
    },
  },
  {
    sentence: (
      <>
        혼자 걸어서 여행할 때처럼 그렇게 내가 완전히 살아 있다는 느낌을 받은
        적이 없었고, 감히 표현하자면 그렇게 완전한 삶을 영위한 적도, 그렇게
        철저하게 나 자신이 되어본 적도 없었다.
      </>
    ),
    tags: ["고독", "산책"],
    origin: {
      type: "책",
      name: "걷기의 즐거움, 장 자크 루소 외 33명",
      url: "https://www.yes24.com/Product/Goods/122895044",
    },
  },
  {
    sentence: <>숲 밖의 일에 몰두할 거라면 굳이 숲에 올 이유가 있겠는가?</>,
    tags: ["고독", "산책"],
    origin: {
      type: "책",
      name: "걷기의 즐거움, 헨리 데이비드 소로 외 33명",
      url: "https://www.yes24.com/Product/Goods/122895044",
    },
  },
  {
    sentence: (
      <>
        재즈의 거장 마일스 데이비스는 방금 연주한 음이 틀렸다 해도 다음 음을
        통해 그 음을 맞는 음으로 바꿀 수 있다고 했다.
      </>
    ),
    tags: ["창작", "영감"],
    origin: {
      type: "책",
      name: "영감의 말들, 김목인",
      url: "https://www.yes24.com/Product/Goods/115850603",
    },
  },
  {
    sentence: (
      <>
        많은 노랫말은 우리가 쓰는 일상어로 되어 있다. 즉 단순한 것으로 대단한
        것을 창조한 모범 사례를 보며 내 작품에도 희망의 싹이 있지 않을까
        생각하는 것이다.
      </>
    ),
    tags: ["창작", "영감"],
    origin: {
      type: "책",
      name: "영감의 말들, 김목인",
      url: "https://www.yes24.com/Product/Goods/115850603",
    },
  },
  {
    sentence: (
      <>
        창작이 하나의 발견이라면 무작정 여기저기 파 보기보다 먼저 '좋은 질문'을
        던지는 게 필요할지 모른다. 일단 좋은 질문을 던지면 파는 곳마다 연관된 게
        끌려 나오기 때문이다.
      </>
    ),
    tags: ["창작", "영감"],
    origin: {
      type: "책",
      name: "영감의 말들, 김목인",
      url: "https://www.yes24.com/Product/Goods/115850603",
    },
  },
  {
    sentence: (
      <>
        예술 작품처럼 딱히 정답이 없는 세계에서 유일한 기준은 자신의 감이다.
        창작자는 대부분의 시간 동안 혼자 무수하고 자잘한 선택을 해 나간다.
        이어질 문장이나 음을 고를 때 그 선택은 몸에 밴 기준에 의해 이루어진다.
      </>
    ),
    tags: ["창작", "영감"],
    origin: {
      type: "책",
      name: "영감의 말들, 김목인",
      url: "https://www.yes24.com/Product/Goods/115850603",
    },
  },
  {
    sentence: (
      <>
        일단 첫 삽을 떠 두는 편이다. 번역할 책을 맡든 컴필레이션 음반에 실을
        곡을 의뢰받든 마찬가지다. 일단 시작이라도 해 두면 효과가 있다. 시작부터
        완벽하려 하지 않고 '슥'해 두는 것이다.
      </>
    ),
    tags: ["창작", "영감"],
    origin: {
      type: "책",
      name: "영감의 말들, 김목인",
      url: "https://www.yes24.com/Product/Goods/115850603",
    },
  },
  {
    sentence: (
      <>
        한 가지 희망이 있다면, 적절히 표현되기만 하면 어떤 소박한 것도 빛을
        발한다는 사실이다.
      </>
    ),
    tags: ["창작", "영감"],
    origin: {
      type: "책",
      name: "영감의 말들, 김목인",
      url: "https://www.yes24.com/Product/Goods/115850603",
    },
  },
  {
    sentence: (
      <>
        내용이야 어차피 내 머리에서 나오는 것을 벗어날 수 없다. 대신 싱싱함을
        기대한다.
      </>
    ),
    tags: ["창작", "영감"],
    origin: {
      type: "책",
      name: "영감의 말들, 김목인",
      url: "https://www.yes24.com/Product/Goods/115850603",
    },
  },
  {
    sentence: (
      <>
        앞으로 나아가는 말의 에너지와 리듬, 그 힘을 빌리는 것이다.<BL></BL>
        그렇다면 어떤 때 말이 풍성하게 흘러나올까? 말하기 편한 상대와 있을 때다.
        그런 상대를 상상하며 두서없이 말하기 시작하는 것이 보통 내 창작의
        바탕이었다.
      </>
    ),
    tags: ["창작", "영감"],
    origin: {
      type: "책",
      name: "영감의 말들, 김목인",
      url: "https://www.yes24.com/Product/Goods/115850603",
    },
  },
  {
    sentence: <>새로운 이야기만큼이나 새롭게 들려주는 이야기의 힘도 크다.</>,
    tags: ["창작", "영감"],
    origin: {
      type: "책",
      name: "영감의 말들, 김목인",
      url: "https://www.yes24.com/Product/Goods/115850603",
    },
  },
  {
    sentence: (
      <>
        영감도 마찬가지다. 영감이 떠올라 일이 일사천리로 풀리는 경우도 있지만,
        가사나 편곡이 잘 풀리지 않을 때면 결국에는 풀리겠지 하는 마음으로 나머지
        부분을 먼저 진행하는 것이 나중에 큰 도움이 된다.
      </>
    ),
    tags: ["창작", "영감"],
    origin: {
      type: "책",
      name: "영감의 말들, 김목인",
      url: "https://www.yes24.com/Product/Goods/115850603",
    },
  },
  {
    sentence: (
      <>
        감상할 때는 정신적 산물로만 여겨졌던 작품이 이렇게 물리적 과정을 통해
        만들어진다는 사실을 잊고 있었다.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "영감의 말들, 김목인",
      url: "https://www.yes24.com/Product/Goods/115850603",
    },
  },
  {
    sentence: (
      <>
        장담컨대 대부분의 일상은 자세히 보면 생각하던 것과 다르다. 그런 것을 잘
        관찰해 보여 주는 것만으로도 충분히 창의적일 수 있다. 상상을 줄일수록 더
        새롭고 희한하다.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "영감의 말들, 김목인",
      url: "https://www.yes24.com/Product/Goods/115850603",
    },
  },
  {
    sentence: (
      <>산책자는 걸을 때만큼은 자신의 '몸'보다 '몸이 아닌 것'에 시선을 둔다.</>
    ),
    tags: ["산책", "창작"],
    origin: {
      type: "책",
      name: "영감의 말들, 김목인",
      url: "https://www.yes24.com/Product/Goods/115850603",
    },
  },
  {
    sentence: (
      <>비범함은 야외를 좋아한다. 집중하는 정신을 좋아한다. 고독을 좋아한다.</>
    ),
    tags: ["고독", "창작"],
    origin: {
      type: "책",
      name: "영감의 말들, 김목인",
      url: "https://www.yes24.com/Product/Goods/115850603",
    },
  },
  {
    sentence: (
      <>
        책을 쓴다는 것은 사랑에 빠지는 것이다. 나를, 혹은 누군가를, 또는
        무엇인가를 사랑하난 사람만이 책을 쓴다. 책 쓰는 고통을 온전히 홀로
        견뎌야 하기 때문이다. 그런 사랑의 결과로 책이라는 자식을 낳게 된다.
      </>
    ),
    tags: ["고독", "창작"],
    origin: {
      type: "책",
      name: "소설은 실패를 먹고 자란다, 정진영",
      url: "https://www.yes24.com/Product/Goods/123634307",
    },
  },
  {
    sentence: (
      <>
        자기만의 방을 가진다면, 그리고 우리가 스스로 생각하는 것을 정확하게
        표현할 수 있는 용기와 자유의 습성을 가지게 된다면, 우리가 공동의
        거실에서 조금 탈출하여 인간을 서로에 대한 관계에서만이 아니라 리얼리티와
        관련하여 본다면, 그리고 하늘이건 나무이건 그 밖의 무엇이건 사물을 그
        자체로 보게 된다면
      </>
    ),
    tags: ["고독", "창작"],
    origin: {
      type: "책",
      name: "자기만의 방, 버지니아 울프",
      url: "https://www.yes24.com/Product/Goods/33631480",
    },
  },
  {
    sentence: (
      <>
        여러분이 쓰고 싶은 것을 쓰는 것, 그것만이 중요한 일입니다. 그 책이 몇
        세대 동안 가치 있을지 아니면 몇 시간 동안만 중요할지는 아무도 예측할 수
        없습니다.<p></p>책을 쓰라고 권하는 것은 여러분 자신에게 그리고 세계
        전반에 도움이 될 일을 하라고 촉구하는 것입니다.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "자기만의 방, 버지니아 울프",
      url: "https://www.yes24.com/Product/Goods/33631480",
    },
  },
  {
    sentence: (
      <>
        여성에게 닫힌 문 때문에 실제로 문학이 측정할 수 없을 정도로 빈곤해진
        것처럼 말이지요.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "자기만의 방, 버지니아 울프",
      url: "https://www.yes24.com/Product/Goods/33631480",
    },
  },
  {
    sentence: (
      <>
        자연은 인간 마음의 벽 위에 보이지 않는 잉크로 위대한 예술가들만이 확증해
        줄 수 있는 어떤 예감을 그려 놓았고, 그것은 오직 천재의 불길이 닿아야
        눈에 보이는 스케치일지도 모릅니다.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "자기만의 방, 버지니아 울프",
      url: "https://www.yes24.com/Product/Goods/33631480",
    },
  },
  {
    sentence: (
      <>
        걸작이란 혼자서 외톨이로 태어나는 것이 아니니까요. 그것은 오랜 세월에
        걸쳐서 한 무리의 사람들이 공동으로 생각한 결과입니다. 그래서 다수의
        경험이 하나의 목소리 이면에 존재하는 것이지요.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "자기만의 방, 버지니아 울프",
      url: "https://www.yes24.com/Product/Goods/33631480",
    },
  },
  {
    sentence: (
      <>
        추측건대, 예술가의 마음은 자기 속에 내재한 작품을 흠 없이 완전하게
        풀어놓으려는 엄청난 노력을 기울이기 위해서 셰익스피어의 마음처럼
        작열해야 합니다. 그 안에 어떤 방해물이 있어서도 안 되고 태워지지 않는
        이물질이 끼어서도 안 됩니다.
      </>
    ),
    tags: ["창작", "고독"],
    origin: {
      type: "책",
      name: "자기만의 방, 버지니아 울프",
      url: "https://www.yes24.com/Product/Goods/33631480",
    },
  },
  {
    sentence: (
      <>
        위대한 작품이 작가의 마음에서 완전하고 총체적인 모습으로 나타날 가능성을
        거스르는 것들이 도처에 존재합니다.<p></p>일반적으로 물적 환경이 그것에
        적대적이지요. 우선 조용한 방이나 방음 장치가 된 방은 말할 것도 없고,
        여성이 자기만의 방을 갖는 것은 그녀의 부모가 보기 드문 부자이거나 대단한
        귀족이 아니라면 19세기 초까지 전혀 불가능한 일이었지요.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "자기만의 방, 버지니아 울프",
      url: "https://www.yes24.com/Product/Goods/33631480",
    },
  },
  {
    sentence: (
      <>
        픽션은 거미집과 같아서 아주 미세하게라도 구석구석 현실의 삶에 부착되어
        있습니다. 종종 그 부착된 상태는 거의 눈에 띄지 않지요. 일례를 들자면
        셰익스피어의 희곡들은 홀로 완벽하게 공중에 매달려 있는 듯 보이지요.
        <p></p>
        그러나 거미집을 비스듬히 잡아당겨 가장자리에 갈고리를 걸고 중간을 찢어
        보면, 이 거미집들은 형체 없는 생물이 공중에서 자아낸 것이 아니라
        고통받는 인간 존재의 작업이며, 건강과 돈 그리고 우리가 사는 집처럼
        조잡한 물질에 부착되어 있다는 사실을 기억하게 됩니다.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "자기만의 방, 버지니아 울프",
      url: "https://www.yes24.com/Product/Goods/33631480",
    },
  },
  {
    sentence: (
      <>
        그것을 드러내지 않으면 죽는 것이나 다름없는 단 하나의 재능이 - 작은
        것이지만 소유자에게는 소중한 - 소멸하고 있으며 그와 함께 나 자신, 나의
        영혼도 소멸하고 있다는 생각, 이 모든 것들이 나무의 생명을 고갈시키며
        봄날의 개화를 잠식하는 녹과 같았습니다.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "자기만의 방, 버지니아 울프",
      url: "https://www.yes24.com/Product/Goods/33631480",
    },
  },
  {
    sentence: (
      <>
        픽션을 쓰기 위해서는 돈과 자기만의 방이 있어야 한다는 의견을 제시하는
        것입니다.
      </>
    ),
    tags: ["고독", "창작"],
    origin: {
      type: "책",
      name: "자기만의 방, 버지니아 울프",
      url: "https://www.yes24.com/Product/Goods/33631480",
    },
  },
  {
    sentence: (
      <>
        문학은 성공과 영광을 위한 것이 아닙니다. 문학은 사업이 아니고, 문학작
        품은 사업적 결과물이 아닙니다. 기본적으로 덧없는 것이고, 그 덧없음의
        힘으로 진실과 직면하는 것이고, 세계와 싸우며 동시에 말을 거는 것입니다.
      </>
    ),
    tags: ["작가"],
    origin: {
      type: "인용",
      name: "한강",
    },
  },
  {
    sentence: (
      <>죽어서 천국으로 가져갈 수 있는 것은 분명히 형체가 없는 것이다.</>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "디자이너 생각 위를 걷다, 나가오카 겐메이",
      url: "https://www.yes24.com/Product/Goods/3565765",
    },
  },
  {
    sentence: (
      <>실제로 그 일을 완성해 가는 과정에서 진실이 무엇인지 정체가 드러난다.</>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "디자이너 생각 위를 걷다, 나가오카 겐메이",
      url: "https://www.yes24.com/Product/Goods/3565765",
    },
  },
  {
    sentence: (
      <>
        사람은 다른 사람으로부터 의뢰가 들어오지 않은 상태에서도 창작을 할 수
        있어야 비로소 창조가가 될 수 있다. 정말 어렵다. 아무도 의뢰하지 않은,
        돈도 받을 수 없는 자기와의 싸움이기 때문이다. 아무리 작은 일이라도
        상관없다. 의뢰받지 않은 일을 시작해 보자.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "디자이너 생각 위를 걷다, 나가오카 겐메이",
      url: "https://www.yes24.com/Product/Goods/3565765",
    },
  },
  {
    sentence: (
      <>
        아무도 없이 홀로일 때 느낄 수 있는 위안과 평안 그리고 어둠. 이것들은
        오직 깊고, 어둡고, 완벽한 고독 속에서만 얻을 수 있다. 새로운 감정은
        그렇게 찾아왔다.
      </>
    ),
    tags: ["작가", "고독"],
    origin: {
      type: "책",
      name: "Alone, 줌파 라히리 외 21명",
      url: "https://www.yes24.com/Product/Goods/119287547",
    },
  },
  {
    sentence: (
      <>
        나의 새로운 취미는 나 자신을 위해 고요함을 누리고, 나만의 경계를 확고히
        함으로써 꿈꿀 수 있는 공간을 확보하는 거였다.
      </>
    ),
    tags: ["작가", "고독"],
    origin: {
      type: "책",
      name: "Alone, 줌파 라히리 외 21명",
      url: "https://www.yes24.com/Product/Goods/119287547",
    },
  },
  {
    sentence: (
      <>
        가부장적인 사회는 자기 자신을 사랑한 죄에 대해 속죄하기 위해선 영원히
        외로움을 느껴야 한다고 위협하면서 여성에게서 고독을 누릴 특권을
        빼앗았다.
      </>
    ),
    tags: ["작가", "고독"],
    origin: {
      type: "책",
      name: "Alone, 줌파 라히리 외 21명",
      url: "https://www.yes24.com/Product/Goods/119287547",
    },
  },
  {
    sentence: (
      <>
        현대인들은 예전보다 더 외로워졌으나 예전보다 혼자 있는 것에 덜 익숙하다.
      </>
    ),
    tags: ["작가", "고독"],
    origin: {
      type: "책",
      name: "Alone, 줌파 라히리 외 21명",
      url: "https://www.yes24.com/Product/Goods/119287547",
    },
  },
  {
    sentence: (
      <>
        작가가 되고 책상이 비로소 나의 집이 되었을 때 나는 더 이상 내가 속할
        곳을 필요로 하지 않게 되었다.
      </>
    ),
    tags: ["작가"],
    origin: {
      type: "책",
      name: "Alone, 줌파 라히리 외 21명",
      url: "https://www.yes24.com/Product/Goods/119287547",
    },
  },
  {
    sentence: (
      <>
        작가는 보고 듣는 직업이지만 정작 작가가 되기 위해서는 귀를 막고 눈을
        가려야 한다.
      </>
    ),
    tags: ["작가"],
    origin: {
      type: "책",
      name: "Alone, 줌파 라히리 외 21명",
      url: "https://www.yes24.com/Product/Goods/119287547",
    },
  },
  {
    sentence: (
      <>
        내가 외롭다고 느끼는 이유는 결코 혼자 있을 수 없기 때문이 아닐까. 고독
        그리고 긴 여정을 혼자 걷기로 한 것은 그나마 가장 덜 외로운 선택이
        아니었을까 싶다.
      </>
    ),
    tags: ["작가", "고독"],
    origin: {
      type: "책",
      name: "Alone, 줌파 라히리 외 21명",
      url: "https://www.yes24.com/Product/Goods/119287547",
    },
  },
  {
    sentence: (
      <>
        글쓰기는 전혀 모르는 사람에게 침묵으로 말을 걸고, 그 이야기는 고독한
        독서를 통해 목소리를 되찾고 울려퍼진다. 그건 글쓰기를 통해 공유되는
        고독이 아닐까. 우리 모두는 눈앞의 인간관계보다는 깊은 어딘가에서 홀로
        지내는 것이 아닐까?
      </>
    ),
    tags: ["작가", "고독"],
    origin: {
      type: "인용",
      name: "리베카 솔닛",
    },
  },
  {
    sentence: (
      <>
        말이 몸에서 흘러나오고, 그 말들을 종이에 새겨 넣는 과정을 느끼는 것이다.
        글쓰기는 촉각적인 면을 갖고 있다. 육체적인 경험이다.
      </>
    ),
    tags: ["작가"],
    origin: {
      type: "인용",
      name: "폴 오스터",
    },
  },
  {
    sentence: <>Men(인류)에 대해 쓰지 말고 man(한 인간)에 대해 쓰라.</>,
    tags: ["작가"],
    origin: {
      type: "인용",
      name: "E.B.화이트",
    },
  },
  {
    sentence: (
      <>
        비비안 마이어는 자신을 둘로 나누어 한 사람은 창작을, 한 사람은 감상과
        지지를 보냈다. 자기의 사진을 보여 주고 싶은 타자는 또 다른 나였던 게
        아닐까 싶다.
      </>
    ),
    tags: ["작가"],
    origin: {
      type: "책",
      name: "쓰기의 말들, 은유",
      url: "http://www.yes24.com/Product/Goods/30177447",
    },
  },
  {
    sentence: (
      <>
        글쓰기란 생각의 과정을 담는 일이다. 생각을 완성하는 게 아니라 중지하는
        것이다. 글쓰기에는 충분한 시간이 아니라 정해진 시간이 필요하다.
      </>
    ),
    tags: ["작가"],
    origin: {
      type: "책",
      name: "쓰기의 말들, 은유",
      url: "http://www.yes24.com/Product/Goods/30177447",
    },
  },
  {
    sentence: (
      <>작품을 완성할 수는 없다. 단지 어느 시점에서 포기하는 것뿐이다.</>
    ),
    tags: ["작가"],
    origin: {
      type: "인용",
      name: "폴 발레리",
    },
  },
  {
    sentence: <>나는 씁니다. 따라서 나는 스스로 안심합니다.</>,
    tags: ["작가"],
    origin: {
      type: "인용",
      name: "롤랑 바르트",
    },
  },
  {
    sentence: (
      <>
        기록한다는 것은 조수간만처럼 끊임없이 침식해 들어오는 인생의 무의미에
        맞서는 일이기도 하죠.
      </>
    ),
    tags: ["작가"],
    origin: {
      type: "인용",
      name: "김영하",
    },
  },
  {
    sentence: (
      <>
        흔들리지 않는 게 아니라 흔들리는 상태를 인식하는 것. 글이 주는 선물
        같다.
      </>
    ),
    tags: ["작가"],
    origin: {
      type: "책",
      name: "쓰기의 말들, 은유",
      url: "http://www.yes24.com/Product/Goods/30177447",
    },
  },
  {
    sentence: (
      <>
        '이만하면'이라는 말은 위험하다. 됐거나 아니거나 둘 중 하나다. 대개의
        원고는 '웬만하면' 한 번 더 다듬는 게 낫다.
      </>
    ),
    tags: ["작가"],
    origin: {
      type: "책",
      name: "쓰기의 말들, 은유",
      url: "http://www.yes24.com/Product/Goods/30177447",
    },
  },
  {
    sentence: <>유일한 참된 충고자 고독이 하는 말을 듣도록.</>,
    tags: ["고독"],
    origin: {
      type: "인용",
      name: "스테판 말라르메",
    },
  },
  {
    sentence: (
      <>
        아무것도 안 하는 것과 아무것도 안 하는 거 '같은' 것의 차이. 하루 이틀은
        쓰나 안 쓰나 똑같지만 한 해 두 해 넘기면 다르다.
      </>
    ),
    tags: ["작가"],
    origin: {
      type: "책",
      name: "쓰기의 말들, 은유",
      url: "http://www.yes24.com/Product/Goods/30177447",
    },
  },
  {
    sentence: (
      <>
        한 사람이 글 쓰는 삶을 살기 위해서는 (연습과 노력 외에) 자기를 알아주는
        사람, 자기를 믿어 주는 사람이 필요하다는 것.
      </>
    ),
    tags: ["작가"],
    origin: {
      type: "책",
      name: "쓰기의 말들, 은유",
      url: "http://www.yes24.com/Product/Goods/30177447",
    },
  },
  {
    sentence: (
      <>
        매일 써서 빈곤해지는 흐름이 있고 매일 써서 풍요해지는 흐름이 있다. 쓰기
        전엔 알 수 없다.
      </>
    ),
    tags: ["작가"],
    origin: {
      type: "책",
      name: "쓰기의 말들, 은유",
      url: "http://www.yes24.com/Product/Goods/30177447",
    },
  },
  {
    sentence: (
      <>
        '나'라는 피할 수도 물릴 수도 없는 출발점, '나'만 살아왔고 살아가는
        엄정한 조건항으로 나를 원위치시킨다.
      </>
    ),
    tags: ["작가"],
    origin: {
      type: "책",
      name: "쓰기의 말들, 은유",
      url: "http://www.yes24.com/Product/Goods/30177447",
    },
  },
  {
    sentence: (
      <>
        작가의 재능이란 사람들이 생각하는 것만큼 희귀하지 않다. 오히려 그 재능은
        많은 시간 동안의 고독을 견디고 계속 작업을 해 나갈 수 있는 능력에서
        부분적으로 드러나기도 한다.
      </>
    ),
    tags: ["동기부여", "고독", "작가"],
    origin: {
      type: "인용",
      name: "리베카 솔닛",
    },
  },
  {
    sentence: (
      <>적절한 장소에 찍힌 마침표만큼 심장을 강하게 꿰뚫는 무기는 없다.</>
    ),
    tags: ["작가"],
    origin: {
      type: "인용",
      name: "이사크 바벨",
    },
  },
  {
    sentence: (
      <>
        글쓰기에 투신할 최소 시간 확보하기. 글을 쓰고 싶다는 이들에게 일상의
        구조 조정을 권한다.
      </>
    ),
    tags: ["작가"],
    origin: {
      type: "책",
      name: "쓰기의 말들, 은유",
      url: "http://www.yes24.com/Product/Goods/30177447",
    },
  },
  {
    sentence: (
      <>
        진흙탕 같은 세상에서 뒹굴더라도 연꽃 같은 언어를 피워 올린다면 삶의
        풍경이 바뀔 수도 있다는 것.
      </>
    ),
    tags: ["작가"],
    origin: {
      type: "책",
      name: "쓰기의 말들, 은유",
      url: "http://www.yes24.com/Product/Goods/30177447",
    },
  },
  {
    sentence: (
      <>
        빨리 쓰기 시작해야 글을 쓰기 전까지 생각하지 못했던 것이 긴장한 가운데
        생각나고 글이 글을 물고 나온다는 것.
      </>
    ),
    tags: ["작가"],
    origin: {
      type: "책",
      name: "쓰기의 말들, 은유",
      url: "http://www.yes24.com/Product/Goods/30177447",
    },
  },
  {
    sentence: <>자기만의 길을 가는 이는 누구와도 만나지 않는다.</>,
    tags: ["동기부여"],
    origin: {
      type: "인용",
      name: "니체",
    },
  },
  {
    sentence: (
      <>
        열린 출구는 단 하나밖에 없다.<p></p>네 속으로 파고 들어가라.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "인용",
      name: "에리히 케스트너",
    },
  },
  {
    sentence: (
      <>
        나무와 꽃들은 햇빛과 물, 산소만으로도 충분히 자신이 원하는 모든 것을
        얻어낸다. 소로는 삶의 기쁨을 위해 많은 것을 필요로 하지 않는 식물에서
        커다란 영감을 받았다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "A journey from loneliness to solitude, mindgraph",
      url: "https://www.mindgraphmagazine.com/product/b9a167b5-4946-48c1-85e1-841aba6a9f8d",
    },
  },
  {
    sentence: (
      <>
        고독은 억지로 외향적인 척 사회생활을 해나가다 소진된 자신에게 휴식을
        부여하는 것으로 한정될 수 없다고. 고독이란 나를 이루는 감각을 부인하지
        않은 채, 내가 바라던 감각을 일깨우며 새로이 발명하는 일종의 체험이라고.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "A journey from loneliness to solitude, mindgraph",
      url: "https://www.mindgraphmagazine.com/product/b9a167b5-4946-48c1-85e1-841aba6a9f8d",
    },
  },
  {
    sentence: (
      <>
        21세기 현대인은 고독을 부정적으로 보기보다는 적극적이고 긍정적으로
        활용할 문화적 도구로 기획해 자신의 것으로 몸과 마음에 익혀야 한다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "A journey from loneliness to solitude, mindgraph",
      url: "https://www.mindgraphmagazine.com/product/b9a167b5-4946-48c1-85e1-841aba6a9f8d",
    },
  },
  {
    sentence: (
      <>
        고독은 세상의 오염에서 잠시 나를 지키고 다듬기 위한 것이다. 고독은 내
        마음이 의도한 것이지만 외로움은 세상이 강요한 것이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "A journey from loneliness to solitude, mindgraph",
      url: "https://www.mindgraphmagazine.com/product/b9a167b5-4946-48c1-85e1-841aba6a9f8d",
    },
  },
  {
    sentence: (
      <>
        무의식이 주도하는 고독은 마음의 조각을 창의적으로 연결한다. 그래서 좋은
        생각이나 글이 고독에서 나온다.
      </>
    ),
    tags: ["고독", "작가"],
    origin: {
      type: "책",
      name: "A journey from loneliness to solitude, mindgraph",
      url: "https://www.mindgraphmagazine.com/product/b9a167b5-4946-48c1-85e1-841aba6a9f8d",
    },
  },
  {
    sentence: <>고독의 고요함은 내가 독점한 시간과 공간에서 나온다.</>,
    tags: ["고독"],
    origin: {
      type: "책",
      name: "A journey from loneliness to solitude, mindgraph",
      url: "https://www.mindgraphmagazine.com/product/b9a167b5-4946-48c1-85e1-841aba6a9f8d",
    },
  },
  {
    sentence: (
      <>
        씨<p></p>그 안에 무엇이 들어있는지<p></p>쪼개어 알아내는 것이 아니라
        <p></p>심고 물을 주어 키워가며 알아내는 것
      </>
    ),
    tags: ["동기부여"],
    origin: {
      type: "책",
      name: "한 글자 사전, 김소연",
      url: "http://www.yes24.com/Product/Goods/58202804",
    },
  },
  {
    sentence: (
      <>고독은 내면에의 침잠을 독려하는 시간이 빚어낸 홀연한 창조의 기적이다.</>
    ),
    tags: ["고독", "작가"],
    origin: {
      type: "책",
      name: "A journey from loneliness to solitude, mindgraph",
      url: "https://www.mindgraphmagazine.com/product/b9a167b5-4946-48c1-85e1-841aba6a9f8d",
    },
  },
  {
    sentence: (
      <>
        작가란 고독과의 싸움에서 살아 돌아온 자다. 그들은 고독을 통해 초월의
        계기를 찾는다.<p></p>뒤라스의 경우에서 볼 수 있듯이, 고독은 누구도
        함부로 넘나들 수 없는 작가의 고유한 영토다. 고독 속에서 창조의 힘이
        길러지고, 고독으로 빚은 것만이 삶과 세계에 대한 찬란한 깊이의 통찰을
        머금을 수 있다. 그런 전제 아래서 고독은 존재의 싹이자 창조의 동력이다.
      </>
    ),
    tags: ["고독", "작가"],
    origin: {
      type: "책",
      name: "A journey from loneliness to solitude, mindgraph",
      url: "https://www.mindgraphmagazine.com/product/b9a167b5-4946-48c1-85e1-841aba6a9f8d",
    },
  },
  {
    sentence: (
      <>
        작가는 한밤중에 자신만의 고독과 마주한다. 글을 쓰기 위해 주변과 거리를
        두어야 하기 때문이다. 글쓰기는 철저하게 혼자만의 작업이다. 고독과
        마주하지 않고는 글쓰기가 이루어지지 않는다.
      </>
    ),
    tags: ["고독", "작가"],
    origin: {
      type: "책",
      name: "A journey from loneliness to solitude, mindgraph",
      url: "https://www.mindgraphmagazine.com/product/b9a167b5-4946-48c1-85e1-841aba6a9f8d",
    },
  },
  {
    sentence: (
      <>
        삶을 더 사랑할수록, 창조적인 일을 할수록 사람은 고독해진다. 작가나 화가,
        음악가에게 고독이 낯설지 않은 것은 모든 예술이 고독에서 빚어지는
        까닭이다.
      </>
    ),
    tags: ["고독", "작가"],
    origin: {
      type: "책",
      name: "A journey from loneliness to solitude, mindgraph",
      url: "https://www.mindgraphmagazine.com/product/b9a167b5-4946-48c1-85e1-841aba6a9f8d",
    },
  },
  {
    sentence: (
      <>
        혼자 있으면 어떤 식으로든 이방인이 되는 순간이 생기고, 그런 순간에
        스멀스멀 문장이 피어난다.
      </>
    ),
    tags: ["고독", "작가"],
    origin: {
      type: "책",
      name: "A journey from loneliness to solitude, mindgraph",
      url: "https://www.mindgraphmagazine.com/product/b9a167b5-4946-48c1-85e1-841aba6a9f8d",
    },
  },
  {
    sentence: (
      <>
        고독은 외로움에는 없는 열매가 있다고. 그리고 고독은 혼자만의 시간을
        우아하게 보내는 방법이자 사람에게 무언가를 안겨주는 시간이라고.
      </>
    ),
    tags: ["고독", "작가"],
    origin: {
      type: "책",
      name: "A journey from loneliness to solitude, mindgraph",
      url: "https://www.mindgraphmagazine.com/product/b9a167b5-4946-48c1-85e1-841aba6a9f8d",
    },
  },
  {
    sentence: (
      <>
        불교에 삼매(三昧)라는 단어가 있다. 잡념에서 벗어나 하나에 집중한다는
        뜻이다.<p></p>작가 역시 자신에게 쏟아지는 수많은 질문을 뒤로하고 글을
        짓는 삼매를 행한다. 그의 삼매로 빚은 책들로 독자로 하여금 저마다 고독의
        시간을 갖게 한다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "A journey from loneliness to solitude, mindgraph",
      url: "https://www.mindgraphmagazine.com/product/b9a167b5-4946-48c1-85e1-841aba6a9f8d",
    },
  },
  {
    sentence: (
      <>
        고독의 상황에서 자신의 생각거리를 스스로 선택해야 한다는 것.<p></p>
        고독하길 원하고 어떤 생각을 할지 자신이 직접 선택하는 것이 진짜 고독으로
        향하는 지름길인 셈이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "A journey from loneliness to solitude, mindgraph",
      url: "https://www.mindgraphmagazine.com/product/b9a167b5-4946-48c1-85e1-841aba6a9f8d",
    },
  },
  {
    sentence: (
      <>
        독일의 철학자 파울 틸리히는 "외로움이란 혼자 있는 고통이고 고독은 혼자
        있는 즐거움"이라고 말합니다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "A journey from loneliness to solitude, mindgraph",
      url: "https://www.mindgraphmagazine.com/product/b9a167b5-4946-48c1-85e1-841aba6a9f8d",
    },
  },
  {
    sentence: (
      <>
        Ne te quaesiveris extra.<p></p>
        (당신 자신을 당신 밖에서 찾지 말라.)
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "어떤 고독은 외롭지 않다, 랠프 월도 에머슨 외 13명",
      url: "http://www.yes24.com/Product/Goods/115569952",
    },
  },
  {
    sentence: (
      <>
        고독은 모든 인간이 매일매일 새롭고 자유롭게 다스릴 수 있는 자신만의
        영토와 같다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "어떤 고독은 외롭지 않다, 엘리스 메이넬 외 13명",
      url: "http://www.yes24.com/Product/Goods/115569952",
    },
  },
  {
    sentence: (
      <>
        명성에 대한 애착에서 벗어나게. 과거 활동에서 나오는 광채가 자네를 너무
        환하게 비추다가 은둔처까지 들어갈까 두렵군.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "어떤 고독은 외롭지 않다, 미셸 드 몽테뉴 외 13명",
      url: "http://www.yes24.com/Product/Goods/115569952",
    },
  },
  {
    sentence: (
      <>
        영예와 평온은 너무 달라서 한집에서 살 수 없다. 이 두 몽상가는 대중에게서
        몸만 숨긴 채 시선은 항상 그랬듯 밖을 향했다.<p></p>이들은 그저 전보다 더
        잘 도약하기 위해, 더 노력하기 위해 잠시 뒤로 물러났다가 군중의
        한가운데로 다시 뛰어들려 하는 것이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "어떤 고독은 외롭지 않다, 미셸 드 몽테뉴 외 13명",
      url: "http://www.yes24.com/Product/Goods/115569952",
    },
  },
  {
    sentence: (
      <>
        힘이 떨어지기 시작해서 더는 외부에 봉사할 수 없는 상황이 되면, 그 힘을
        우리 안으로 거둬들여서 자신에게 집중해야 한다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "어떤 고독은 외롭지 않다, 미셸 드 몽테뉴 외 13명",
      url: "http://www.yes24.com/Product/Goods/115569952",
    },
  },
  {
    sentence: (
      <>
        우리는 반드시 혼자 있을 수 있어야 하며, 거기서 진정으로 자유로워질 수
        있는 나만의 방을 마련해야 한다.<p></p>고독을 올바르게 활용하는 법을 아는
        사람들에게 그런 칩거란 세계 속에 또 하나의 세계가 있는 것과 같다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "어떤 고독은 외롭지 않다, 미셸 드 몽테뉴 외 13명",
      url: "http://www.yes24.com/Product/Goods/115569952",
    },
  },
  {
    sentence: (
      <>
        세상사에 집착하는 한 수도원에 가든 사막에 가든 그것은 언제나 우리를
        따라다닌다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "어떤 고독은 외롭지 않다, 미셸 드 몽테뉴 외 13명",
      url: "http://www.yes24.com/Product/Goods/115569952",
    },
  },
  {
    sentence: (
      <>
        고독의 공간이 있다<p></p>
        바다의 고독<p></p>
        죽음의 고독, 하지만<p></p>
        이런 곳들도<p></p>좀 더 심오한 곳과는 비교할 수 없으니<p></p>그 극적이자
        사적인 공간인<p></p>
        영혼이 머무는 곳이야말로<p></p>
        고독이 끝나지 않는 곳
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "어떤 고독은 외롭지 않다, 에밀리 디킨슨 외 13명",
      url: "http://www.yes24.com/Product/Goods/115569952",
    },
  },
  {
    sentence: (
      <>
        사색하는 사람이나 일하는 사람은 어디에 있든 항상 혼자다. 고독은 한
        사람과 그의 동료들 사이에 존재하는 거리로 잴 수 없다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "어떤 고독은 외롭지 않다, 헨리 데이비드 소로 외 13명",
      url: "http://www.yes24.com/Product/Goods/115569952",
    },
  },
  {
    sentence: <>우리는 꿈꾸듯 살아간다. 혼자서</>,
    tags: ["고독"],
    origin: {
      type: "인용",
      name: "조지프 콘래드",
    },
  },
  {
    sentence: (
      <>모든 인간의 문제는 조용한 방에 혼자 앉아 있지 못하는 데서 비롯된다.</>
    ),
    tags: ["고독"],
    origin: {
      type: "인용",
      name: "블레즈 파스칼",
    },
  },
  {
    sentence: (
      <>
        계시는 침묵과 고독 속에 내려온다. 가장 심오한 감정은 자제 속에 모습을
        드러낸다.
      </>
    ),
    tags: ["고독", "창작"],
    origin: {
      type: "책",
      name: "고독의 창조적 기쁨, 펜턴 존슨",
      url: "http://www.yes24.com/Product/Goods/103632754",
    },
  },
  {
    sentence: (
      <>
        나르시시스트는 작품을 통해 인정받고 싶어 하지만 독신자는 작품을 만드는
        일에 만족한다.
      </>
    ),
    tags: ["고독", "창작"],
    origin: {
      type: "책",
      name: "고독의 창조적 기쁨, 펜턴 존슨",
      url: "http://www.yes24.com/Product/Goods/103632754",
    },
  },
  {
    sentence: (
      <>
        지혜는 듣는 것에서 시작되고 듣기는 침묵에서 시작되며 침묵은 고독에
        뿌리를 두고 있다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "고독의 창조적 기쁨, 펜턴 존슨",
      url: "http://www.yes24.com/Product/Goods/103632754",
    },
  },
  {
    sentence: (
      <>
        독신자에게 그 무엇에 비할 데 없이 소중한 보답은 존재의 핵심에서 만날 수
        있는 위대한 침묵을 경험할 수 있다는 것이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "고독의 창조적 기쁨, 펜턴 존슨",
      url: "http://www.yes24.com/Product/Goods/103632754",
    },
  },
  {
    sentence: (
      <>
        명상하는 은둔자, 목적지 도착을 앞둔 여행자는 책에 빠져들어 시간 밖에
        머무른다는 것이 무슨 의미인지 이해할 수 있을 것이다. 과거도 미래도 아닌,
        신비주의자들이 말하는 영원히 지속되는 현재에 사는 것의 의미를 말이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "고독의 창조적 기쁨, 펜턴 존슨",
      url: "http://www.yes24.com/Product/Goods/103632754",
    },
  },
  {
    sentence: (
      <>글을 쓰는 일은 그저 고독하다. 외로움과 고독함은 다르다 생각한다.</>
    ),
    tags: ["고독", "창작"],
    origin: {
      type: "인용",
      name: "유도라 웰티",
    },
  },
  {
    sentence: (
      <>
        자아는 우리를 싣고 외로움에서 고독으로 내적 여행을 떠나는 보트와 같다.
        <p></p>"외로움의 치료 약은 고독이다." 메리앤 무어가 말했다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "고독의 창조적 기쁨, 펜턴 존슨",
      url: "http://www.yes24.com/Product/Goods/103632754",
    },
  },
  {
    sentence: (
      <>
        우주는 하나(uni)와 방향(verse)이 만나 만들어진 단어로, 독신자가 나아가는
        방향이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "고독의 창조적 기쁨, 펜턴 존슨",
      url: "http://www.yes24.com/Product/Goods/103632754",
    },
  },
  {
    sentence: (
      <>
        "고독만이, 온전한 고독만이 시작과 완성을 가능하게 한다."<p></p>세잔의
        미학적 멘토인 들라크루아가 말했다.
      </>
    ),
    tags: ["고독", "창작"],
    origin: {
      type: "책",
      name: "고독의 창조적 기쁨, 펜턴 존슨",
      url: "http://www.yes24.com/Product/Goods/103632754",
    },
  },
  {
    sentence: (
      <>
        소로는 고독이란 사람과 사람 사이의 공간적 거리로 측정되는 것이 아니라고
        생각했다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "고독의 창조적 기쁨, 펜턴 존슨",
      url: "http://www.yes24.com/Product/Goods/103632754",
    },
  },
  {
    sentence: (
      <>
        말과 개념은 인간의 공통된 모습을 깨닫게 하지만, 침묵은 자아의 언어이며
        개인의 특별한 언어다. 그러므로 침묵 속에서만 내 자신을 깨닫게 된다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "고독의 창조적 기쁨, 펜턴 존슨",
      url: "http://www.yes24.com/Product/Goods/103632754",
    },
  },
  {
    sentence: (
      <>
        스스로 자신을 구원한다. 인생에서 가장 위대한 성취는 자신을 구원하는
        길이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "고독의 창조적 기쁨, 펜턴 존슨",
      url: "http://www.yes24.com/Product/Goods/103632754",
    },
  },
  {
    sentence: (
      <>작가와 예술가가 되기 위해서는 고독을 충분히 체험해야 하기 때문이다.</>
    ),
    tags: ["고독", "창작"],
    origin: {
      type: "책",
      name: "고독의 창조적 기쁨, 펜턴 존슨",
      url: "http://www.yes24.com/Product/Goods/103632754",
    },
  },
  {
    sentence: <>예술가는 매 순간 홀로 싸워야 한다.</>,
    tags: ["고독", "창작"],
    origin: {
      type: "책",
      name: "고독의 창조적 기쁨, 펜턴 존슨",
      url: "http://www.yes24.com/Product/Goods/103632754",
    },
  },
  {
    sentence: (
      <>
        고독을 추구하거나 독신의 삶을 살고 있는 수백만 사람들에게 무언가 심오한
        지혜가 숨어 있는 건 아닐까.<p></p>어쩌면 고독은 이제야 꽃 피울 수 있게
        된 인간의 특성 가운데 하나일지도 모른다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "고독의 창조적 기쁨, 펜턴 존슨",
      url: "http://www.yes24.com/Product/Goods/103632754",
    },
  },
  {
    sentence: (
      <>
        나는 글쓰기가, 문장이 쓰이고 있는 백지가 나만의 정신적 공간이 되기를
        바란다.
      </>
    ),
    tags: ["고독", "창작"],
    origin: {
      type: "책",
      name: "친애하는 나의 집에게, 하재영",
      url: "http://www.yes24.com/Product/Goods/95713416",
    },
  },
  {
    sentence: (
      <>
        디킨슨처럼 아무데도 가지 않는 여행자를, 먼 곳을 떠도는 은둔자를 나는
        흠모한다. 나의 방-작업실-서재가 내면으로 들어가는 길이자 외부로 나가는
        길이기를 바란다.<BL></BL>책상 앞에 앉을 때마다 디킨슨이 했던 말을
        떠올린다.<p></p>"이제 자유야."
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "친애하는 나의 집에게, 하재영",
      url: "http://www.yes24.com/Product/Goods/95713416",
    },
  },
  {
    sentence: (
      <>
        고독한 분투의 장소였을 방에서 그는 오랫동안 은둔 생활을 했다. 나는
        디킨슨이 자신이 바라던 대로 방 안에서 자유로웠으리라고, 적막하지만
        활화산처럼 폭발적인 시간을 보냈으리라고 짐작한다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "친애하는 나의 집에게, 하재영",
      url: "http://www.yes24.com/Product/Goods/95713416",
    },
  },
  {
    sentence: (
      <>
        읽는 데에서 나아가 쓰는 사람이 되고자 한다면 자기만의 방이 가지는 의미는
        더 각별해진다.<BL></BL>메리 올리버는 말했다.<p></p>"창작은 고독을
        요한다."<BL></BL>덧붙이자면 고독은 장소를 요한다. 휴대전화를 꺼놓을 수
        있고, 창문을 닫아둘 수 있으며, 나를 부르는 타인의 목소리를 듣지 않을 수
        있는 장소.<p></p>실생활과 최대한 먼 장소. 영감의 순간에 이를 때까지
        침잠하고 몰입할 수 있는 장소.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "친애하는 나의 집에게, 하재영",
      url: "http://www.yes24.com/Product/Goods/95713416",
    },
  },
  {
    sentence: (
      <>
        자기만의 공간을 소유한다는 것은 자기만의 시간을 확보한다는 의미다.
        <p></p>반대로 자기만의 공간이 없다는 것은 자기만의 시간이 언제든
        방해받을 수 있다는 의미다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "친애하는 나의 집에게, 하재영",
      url: "http://www.yes24.com/Product/Goods/95713416",
    },
  },
  {
    sentence: <>글을 쓰기 전에 어느 정도 꾸물거리는 시간은 필요하다.</>,
    tags: ["고독"],
    origin: {
      type: "인용",
      name: "존 스타인벡",
    },
  },
  {
    sentence: (
      <>
        고집은 작가나 예술가에게 중요하죠. 자신이 하고, 계획하고, 노력하고 있는
        게 가치 있다고 믿어야 합니다.<p></p>필요한 게 고집이었다는 걸 나중에야
        알게 되죠.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "인용",
      name: "줄리언 반스",
    },
  },
  {
    sentence: (
      <>
        담배도 안 피우고 술도 안 마십니다.<p></p>제가 사치스럽게 누리는 건
        상상력뿐이죠.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "인용",
      name: "줄리언 반스",
    },
  },
  {
    sentence: (
      <>
        프랑스에 이런 표현이 있어요.<BL></BL>Bien dans sa peau(비앙 당 사 포)
        <BL></BL>
        서재에 있는 순간이 가장 행복하다는 건 아니지만 제게 잘 맞는 껍질 안에
        있고 가장 나답다고 느낍니다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "인용",
      name: "줄리언 반스",
    },
  },
  {
    sentence: (
      <>
        나는 나만의 스타일을 고수하고 나만의 방식대로 계속 써야 한다.<p></p>
        그렇게 해서 다시 성공하지 못할 수도 있지만, 그렇게 하지 않으면 반드시
        실패한다고 확신하니까.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "인용",
      name: "제인 오스틴",
    },
  },
  {
    sentence: (
      <>
        당신이 쓰고 싶은 것을 쓰는 것, 중요한 것은 오직 그뿐이다.<p></p>그것이
        오랫동안 가치 있을지, 아니면 몇 시간 안에 사라질지는 아무도 모른다.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "인용",
      name: "버지니아 울프",
    },
  },
  {
    sentence: (
      <>
        그럼 인간의 말은 어디에서 나와야 할까. 그것은 마땅히 침묵에서 나와야
        한다. 침묵을 배경으로 하지 않는 말은 소음과 다를 게 없다.<p></p>인간은
        침묵 속에서만이 사물을 깊이 통찰할 수 있고 또한 자기 존재를 자각한다.
        이때 비로소 자기 언어를 갖게 되고 자기 말에 책임을 느낀다. 그러기 때문에
        투명한 사람끼리는 말이 없어도 즐겁다. 소리를 입 밖에 내지 않을 뿐 무수한
        말이 침묵 속에서 오고 간다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "무소유, 법정",
      url: "http://www.yes24.com/Product/Goods/1789",
    },
  },
  {
    sentence: (
      <>
        참선을 하는 선원에서는 선실 안팎에 '묵언'이라고 쓴 표지가 붙어 있다.
        말을 말자는 것. 말을 하게 되면 서로가 정진에 방해되기 때문이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "무소유, 법정",
      url: "http://www.yes24.com/Product/Goods/1789",
    },
  },
  {
    sentence: (
      <>
        무엇인가를 갖는다는 것은 다른 한편 무엇인가에 얽매인다는 것이다. 필요에
        따라 가졌던 것이 도리어 우리를 부자유하게 얽어맨다고 할 때 주객이
        전도되어 우리는 가짐을 당하게 된다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "무소유, 법정",
      url: "http://www.yes24.com/Product/Goods/1789",
    },
  },
  {
    sentence: (
      <>
        그분에 대해서, 자네의 위대한 스승에 대해 생각할 때도 나는 말보다 사물을
        더 좋아하네. 그분의 행위와 삶을 그분의 가르침보다도 더 중요하게 여기고,
        그분의 손짓 하나하나가 그분의 의견보다도 더 중요하다네. 나는 그분의
        설법, 그분의 사상에서 그분의 위대함을 깨닫는 게 아니라, 오직 행위와 삶
        속에서 그분의 위대함을 깨닫게 된다네.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "싯다르타, 헤르만 헤세",
      url: "http://www.yes24.com/Product/Goods/90323233",
    },
  },
  {
    sentence: (
      <>
        지식은 전달할 수 있지만 지혜는 그럴 수가 없네. 지혜를 찾아낼 수 있고,
        체험할 수 있고, 지니고 다닐 수 있고, 기적으로 행할 수 있지만, 지혜를
        말하고 가르칠 수는 없네.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "싯다르타, 헤르만 헤세",
      url: "http://www.yes24.com/Product/Goods/90323233",
    },
  },
  {
    sentence: (
      <>
        내가 발견한 사상들 중의 하나는 지혜라는 것은 남에게 전달할 수 없는
        것이네. 아무리 현명한 사람이 전달하려고 애써 봐도 지혜란 언제나 어리석은
        생각으로만 들릴 뿐이라네.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "싯다르타, 헤르만 헤세",
      url: "http://www.yes24.com/Product/Goods/90323233",
    },
  },
  {
    sentence: (
      <>
        그 어떤 가르침도 나를 가르치지 못할 것이다. 나는 나 자신에게서 배울
        것이다. 나는 스스로 나의 제자가 될 것이다. 나는 나를, 싯다르타라는
        비밀을 알아낼 것이다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "싯다르타, 헤르만 헤세",
      url: "http://www.yes24.com/Product/Goods/90323233",
    },
  },
  {
    sentence: (
      <>
        자기 자신의 내면에까지 파고 들어가 본 사람만이 그렇게 진심으로 바라보고
        그렇게 걸어갈 수 있을 것이다. 좋다. 나도 내 자신의 궁극의 심부에까지
        파고 들어가기 위해 탐색하리라.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "싯다르타, 헤르만 헤세",
      url: "http://www.yes24.com/Product/Goods/90323233",
    },
  },
  {
    sentence: (
      <>
        하나의 풍경 속에 타인과 다른 모습을 파악하고, 타인과 다른 것을 느끼며,
        타인과 다른 말을 선택할 수 있는 가능성을 지님으로써, 나만의 이야기를
        써나갈 수 있는 것이다. 그리고 결코 적지 않은 수의 사람들이 내가 쓴 것을
        손에 들고 읽어준다는 드문 상황도 생겨난다.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "달리기를 말할 때 내가 하고 싶은 이야기, 무라카미 하루키",
      url: "http://www.yes24.com/Product/Goods/3239082",
    },
  },
  {
    sentence: (
      <>
        창작자에게 있어 그 동기는 자신 안에 조용히 확실하게 존재하는 것으로서,
        외부에서 어떤 형태나 기준을 찾아야 할 일은 아니다.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "달리기를 말할 때 내가 하고 싶은 이야기, 무라카미 하루키",
      url: "http://www.yes24.com/Product/Goods/3239082",
    },
  },
  {
    sentence: (
      <>
        장편소설을 쓰고 있을 때와 똑같은 요령이다. 더 쓸 만하다고 생각될 때
        과감하게 펜을 놓는다. 그렇게 하면 다음 날 집필을 시작할 때 편해진다.
        어니스트 헤밍웨이도 아마 비슷한 이야기를 썼던 것으로 기억하고 있다.
        <p></p>계속하는 것-- 리듬을 단절하지 않는 것. 장기적인 작업을 하는
        데에는 그것이 중요하다.
      </>
    ),
    tags: ["창작"],
    origin: {
      type: "책",
      name: "달리기를 말할 때 내가 하고 싶은 이야기, 무라카미 하루키",
      url: "http://www.yes24.com/Product/Goods/3239082",
    },
  },
  {
    sentence: (
      <>
        다른 사람이 할 수 없는 것에 도전하는 과정에서 부딪히는 난관은 어느
        시대나 존재했다. 그러나 출발선에 서는 일은 전보다 훨씬 더 쉬워졌다. 이는
        분명 큰 변화이다.
      </>
    ),
    tags: ["동기부여"],
    origin: {
      type: "책",
      name: "권외편집자, 츠즈키 쿄이치",
      url: "http://www.yes24.com/Product/Goods/36953980",
    },
  },
  {
    sentence: (
      <>
        감각이 아닌 경험치를 통해 일을 해온 베테랑 프로들은 앞으로 점점 더
        경쟁에서 밀리게 될지도 모른다.
      </>
    ),
    tags: ["동기부여"],
    origin: {
      type: "책",
      name: "권외편집자, 츠즈키 쿄이치",
      url: "http://www.yes24.com/Product/Goods/36953980",
    },
  },
  {
    sentence: (
      <>
        1%의 성공적인 만남은 '별로일 거야'하고 생각하면서도 유턴할 수 있는가
        아닌가에 달려있다.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "권외편집자, 츠즈키 쿄이치",
      url: "http://www.yes24.com/Product/Goods/36953980",
    },
  },
  {
    sentence: (
      <>
        자신의 생각에 공감해주는 사람은 '한 명 한 명의 독자'이지 '독자층'이
        아니다.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "권외편집자, 츠즈키 쿄이치",
      url: "http://www.yes24.com/Product/Goods/36953980",
    },
  },
  {
    sentence: (
      <>
        정말 엄청난 책을 만드는 사람은 평범하고 과묵하며 혼자서 꾸준히 하는
        작업을 좋아하는 이들뿐이었다. 말로 설명하지 않고 무언가를 만들어서
        모두에게 보여주는 행위가 그들에게는 일종의 커뮤니케이션인 것이다.
      </>
    ),
    tags: ["창작", "동기부여", "고독"],
    origin: {
      type: "책",
      name: "권외편집자, 츠즈키 쿄이치",
      url: "http://www.yes24.com/Product/Goods/36953980",
    },
  },
  {
    sentence: (
      <>
        필요한 것은 오직 고독, 커다란 내면적 고독뿐입니다. 자신에게로 침잠하여
        몇 시간이고 아무도 만나지 않는 것, 이것이 이루어지지 않으면 안 됩니다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "젊은 시인에게 보내는 편지, 라이너 마리아 릴케",
      url: "http://www.yes24.com/Product/Goods/60648038",
    },
  },
  {
    sentence: (
      <>
        당신의 고독을 사랑하십시오. 그리고 그것이 아름다운 비탄의 소리를 내며
        당신에게 주는 고통을 견디십시오.<p></p>왜냐하면 당신과 가까운 사람들이
        멀게 여겨진다고 당신은 말합니다만, 그것은 당신의 주변이 넓어지기 시작한
        표시이기 때문입니다.<p></p>그리고 만약 당신의 가까운 것이 멀리에 있다면,
        당신의 영역은 이미 별들 사이에까지 퍼져서 실로 커다란 것입니다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "젊은 시인에게 보내는 편지, 라이너 마리아 릴케",
      url: "http://www.yes24.com/Product/Goods/60648038",
    },
  },
  {
    sentence: (
      <>
        10년쯤은 아무것도 아닙니다. 예술가란 계산하거나 세는 것이 아니고,
        수목처럼 성숙하는 것입니다.<p></p>수목은 그 수액의 흐름을 재촉하지 않고,
        봄날의 폭풍우 속에 유유히 서서 혹시 여름이 안 오는가 하고 걱정 같은 것은
        하지 않습니다.<p></p>여름은 꼭 옵니다.
      </>
    ),
    tags: ["동기부여"],
    origin: {
      type: "책",
      name: "젊은 시인에게 보내는 편지, 라이너 마리아 릴케",
      url: "http://www.yes24.com/Product/Goods/60648038",
    },
  },
  {
    sentence: (
      <>
        당신이 바깥으로 눈을 돌려, 아마도 당신의 가장 조용한 시간에 당신의 가장
        내면적인 감정만이 대답할 수 있을 물음에 대하여 바깥에서 대답을 기대하는
        것만큼, 당신의 발전을 크게 해치는 것도 없습니다.
      </>
    ),
    tags: ["동기부여", "고독"],
    origin: {
      type: "책",
      name: "젊은 시인에게 보내는 편지, 라이너 마리아 릴케",
      url: "http://www.yes24.com/Product/Goods/60648038",
    },
  },
  {
    sentence: (
      <>
        무언가를 시작하는 데 흔들리지 않는 의지나 숭고한 동기 같은 건 없어도 돼.
        얼떨결에 시작한 게 조금씩 소중해지기도 하지.
        <BL></BL>시작할 때 필요한 건 작은 호기심 정도야.
      </>
    ),
    tags: ["동기부여"],
    origin: {
      type: "영화",
      name: "하이큐",
      url: "https://movie.naver.com/movie/bi/mi/basic.naver?code=140803",
    },
  },
  {
    sentence: <>88개의 유한한 건반에서 자네는 무한한 음악을 만들 수 있어.</>,
    tags: ["동기부여"],
    origin: {
      type: "영화",
      name: "피아니스트의 전설",
      url: "https://movie.naver.com/movie/bi/mi/basic.naver?code=29059",
    },
  },
  {
    sentence: (
      <>
        "빌리. 마지막으로 하나만 물어보자. 넌 춤을 출 때 어떤 생각이 드니?"
        <BL></BL>
        "그냥 좋아요. 활활 타오르는 불길 같은 느낌. 날아가는 것 같아요. 모든 걸
        잊어버려요. 춤을 출 때 저는 한 마리 새처럼 날고 있어요."
      </>
    ),
    tags: ["동기부여"],
    origin: {
      type: "영화",
      name: "빌리 엘리어트",
      url: "https://movie.naver.com/movie/bi/mi/basic.naver?code=31013",
    },
  },
  {
    sentence: (
      <>
        매 순간 최선을 다해 번아웃되지 않고 최선 직전에서 어슬렁거리며 간 보기.
        준최선으로 비벼 보기. 멀리 봤을 때, 최선보다 준최선이 가성비가 더 좋을지
        모른다.<p></p>최선은 관성을 깨는 행위이기 때문에 관성이나 습관이 될 수
        없지만, 준최선은 관성이 될 수 있기 때문이다.<BL></BL>
        대충하는 것은 아닌데 최선을 다하는 것도 아니고 그 사이에서 묵묵하게
        롱런하기.
      </>
    ),
    tags: ["동기부여"],
    origin: {
      type: "책",
      name: "준최선의 롱런, 문보영",
      url: "http://www.yes24.com/Product/Goods/83526545",
    },
  },
  {
    sentence: (
      <>
        앞으로 가고 있다는 느낌을 간직하는 것이 중요했다.<BL></BL>이 세상이
        커다란 러닝 머신일지라도,<p></p>누군가 우리를 보며<p></p>'저 인간들
        제자리에서 뛰고 있어'라고 비웃을지라도,<p></p>나는 어디론가 가고 있기
        때문에 주저앉지 않을 수 있었다.
        <BL></BL>
        간다는 느낌과 함께 걸을 수 있었다.
      </>
    ),
    tags: ["동기부여"],
    origin: {
      type: "책",
      name: "준최선의 롱런, 문보영",
      url: "http://www.yes24.com/Product/Goods/83526545",
    },
  },
  {
    sentence: <>시는 만든 사람의 것이 아니라 필요로 하는 사람의 것이에요.</>,
    tags: ["동기부여"],
    origin: {
      type: "영화",
      name: "일 포스티노",
      url: "https://movie.naver.com/movie/bi/mi/basic.naver?code=17796",
    },
  },
  {
    sentence: (
      <>
        가깝지 않지만 지지하고 싶은 사람들이 있다. 내 글들은 그들에게 갈 것이다.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "당신의 글은 어떻게 시작되었나요?, 강준서 외 6명",
      url: "http://www.yes24.com/Product/Goods/68719229",
    },
  },
  {
    sentence: (
      <>
        개인이 삶속에서 만들어 내는 의미나 가치는 그 자신만이 오롯이 느끼고
        안다. 숫자나 물리적 성과로 그것을 재단하려 해도 스스로 이 일이 충분히
        의미있음을 느끼고 나면 그 경험의 가치들은 잘려나가지 않는다.
      </>
    ),
    tags: ["동기부여"],
    origin: {
      type: "책",
      name: "당신의 글은 어떻게 시작되었나요?, 강준서 외 6명",
      url: "http://www.yes24.com/Product/Goods/68719229",
    },
  },
  {
    sentence: (
      <>
        경험해 보지 않은 사람의 조언과 충고가 내가 만들려는 아름다움을
        질식시키는 경우가 굉장히 많다.
      </>
    ),
    tags: ["동기부여"],
    origin: {
      type: "책",
      name: "당신의 글은 어떻게 시작되었나요?, 강준서 외 6명",
      url: "http://www.yes24.com/Product/Goods/68719229",
    },
  },
  {
    sentence: (
      <>
        그 때 나에게는 과거의 감정이었던 것이 누군가에게는 현재가 된다. 글을
        통해서 우리의 감정이 순환된다.
        <BL></BL>
        종잇장 사이에서 우리가 아주 깊이 호흡을 공유한다는 것이 얼마나 근사한
        일인가.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "당신의 글은 어떻게 시작되었나요?, 강준서 외 6명",
      url: "http://www.yes24.com/Product/Goods/68719229",
    },
  },
  {
    sentence: (
      <>
        심심한 감정과 고독한 감정은 다르다.
        <BL></BL>
        심심할 때는 사람들과 어울리는 걸 정말 좋아하지만 내가 혼자 감당해야 하는
        감정이 밀려드는 시간에는 착실히 나만의 시간을 보내려 한다.
      </>
    ),
    tags: ["고독"],
    origin: {
      type: "책",
      name: "당신의 글은 어떻게 시작되었나요?, 강준서 외 6명",
      url: "http://www.yes24.com/Product/Goods/68719229",
    },
  },
  {
    sentence: (
      <>
        무수한 당신이 걸려 넘어지는 어느 순간에 나의 글이 당신의 무릎 밑에 먼저
        깔리기를 바란다.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "당신의 글은 어떻게 시작되었나요?, 강준서 외 6명",
      url: "http://www.yes24.com/Product/Goods/68719229",
    },
  },
  {
    sentence: (
      <>
        그런 말과 물음에 얽매여 버려서 아무것도 할 수 없게 돼. 작가가 돼야겠다고
        생각하지 말고 차라리 글을 써야겠다고 생각을 해.
        <BL></BL>
        작가라는 이름 따위... 글을 써. 작가 같은 거 하지 말고. 나는 그냥 내
        이야기를 글이라는 형식을 빌려서 쓴 거야.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "당신의 글은 어떻게 시작되었나요?, 강준서 외 6명",
      url: "http://www.yes24.com/Product/Goods/68719229",
    },
  },
  {
    sentence: (
      <>
        좋아하는 문장, 잘 써진 글들을 많이 읽으면 그러한 문장의 구성과 형식이
        마치 후크송처럼 머리에 남아 언젠가는 자신의 생각을 그 문장의 형식에 담아
        구사할 수 있게 된다는 것.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "당신의 글은 어떻게 시작되었나요?, 강준서 외 6명",
      url: "http://www.yes24.com/Product/Goods/68719229",
    },
  },
  {
    sentence: (
      <>
        그렇게 그는 쓴다.<BL></BL>
        매일 밤 모니터를 향해 목을 길게 뽑은 채로, 돈으로도 명예로도 바꿀 수
        없는 자기 자신을 쓰고 지우고 쓰고 지운다.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "당신의 글은 어떻게 시작되었나요?, 강준서 외 6명",
      url: "http://www.yes24.com/Product/Goods/68719229",
    },
  },
  {
    sentence: (
      <>
        여백 앞에서 버티는 이 시간이 모이면 모일수록 생각은 명료해지고 글은 점점
        더 좋아질 거야.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "당신의 글은 어떻게 시작되었나요?, 강준서 외 6명",
      url: "http://www.yes24.com/Product/Goods/68719229",
    },
  },
  {
    sentence: (
      <>
        분량이 어떻든 간에 한 편의 소설을 쓰고 나면 운명처럼 정해진 끝을 향해
        내달리는 한 번의 생을 살아 본 것 같은 기분이 들기도 하는 것이다.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "당신의 글은 어떻게 시작되었나요?, 강준서 외 6명",
      url: "http://www.yes24.com/Product/Goods/68719229",
    },
  },
  {
    sentence: (
      <>
        자신에게 주어진 마음의 느낌, 그것이 무엇이든 한껏 질척이고 글로 남기기.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "당신의 글은 어떻게 시작되었나요?, 강준서 외 6명",
      url: "http://www.yes24.com/Product/Goods/68719229",
    },
  },
  {
    sentence: (
      <>
        좋은 글이란 밑줄을 그을 수밖에 없는 힘있는 문장이 있고 나머지 부분에서는
        최대한 힘을 빼고 쓴 편안한 문장들이 조화를 이루고 있는 글이다.<BL></BL>
        멋진 글을 쓰고 싶다는 욕심을 모든 문장에 투영하게 된다면 그야말로
        대참사가 일어난다.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "당신의 글은 어떻게 시작되었나요?, 강준서 외 6명",
      url: "http://www.yes24.com/Product/Goods/68719229",
    },
  },
  {
    sentence: <>무엇을 쓰기 전에 무엇이 나를 쓰게하는지 생각한다.</>,
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "당신의 글은 어떻게 시작되었나요?, 강준서 외 6명",
      url: "http://www.yes24.com/Product/Goods/68719229",
    },
  },
  {
    sentence: (
      <>
        매순간 흩어져 가는 나의 파편을 현재형으로 기록하며 내가 살아가고 있다는
        증거를 꾸준히 수집하고 싶다.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "당신의 글은 어떻게 시작되었나요?, 강준서 외 6명",
      url: "http://www.yes24.com/Product/Goods/68719229",
    },
  },
  {
    sentence: (
      <>
        내가 만드는 책은 과거의 나를 묻는 무덤이 된다.<p></p>
        책을 만들 때마다 어떤 간극 속의 나를 보존하기 위한 무덤 하나를 짓는
        기분이다. 책을 만듦으로써 흐르는 시간 속으로 스러져가는 나의 존재를
        가까스로 붙잡아둘 것이다.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "당신의 글은 어떻게 시작되었나요?, 강준서 외 6명",
      url: "http://www.yes24.com/Product/Goods/68719229",
    },
  },
  {
    sentence: (
      <>
        두 개의 단어 사이에는 잠재된 우주가 있다.<p></p>
        단어와 단어 사이에는 새순처럼 파생하는 의미가 있고 그로부터 인식의
        세계를 확장할 수 있다.
        <p></p>
        나의 정신적인 우주는 단어와 단어 사이에 존재하고 그곳에서 나는 무한히
        무너지고 일어설 수 있을 것만 같다.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "당신의 글은 어떻게 시작되었나요?, 강준서 외 6명",
      url: "http://www.yes24.com/Product/Goods/68719229",
    },
  },
  {
    sentence: (
      <>
        어떤 철학자는 언어의 한계가 세계의 한계라 했다.
        <BL></BL>
        우리가 더 많은 단어들을 선별하여 글을 쓰지 않는다면 우리는 고작 수백개의
        단어들만 반복해서 사용하며 살아가게 될 것이다. 그런 식으로 우리의 세계는
        점점 더 좁아지고 있는 것일지도 모른다.
        <BL></BL>
        감정의 실체에 가까이 다가가기 위해서는, 그림자 너머에 있는 진짜 세계를
        비추기 위해서는 더 많은 단어를 말하고 써야 한다. 좋은 그릇이 되는 문장을
        쓰기 위해서는 끊임없이 고심해야 한다.<p></p>
        그래야만 우리는 우리 자신을 이해할 수 있다.
      </>
    ),
    tags: ["창작", "동기부여"],
    origin: {
      type: "책",
      name: "당신의 글은 어떻게 시작되었나요?, 강준서 외 6명",
      url: "http://www.yes24.com/Product/Goods/68719229",
    },
  },
  {
    sentence: (
      <>
        바람이 불면 낙엽이 떨어진다,<p></p>낙엽이 떨어지면 땅이 비옥해진다.
        <p></p>땅이 비옥해지면 열매가 열린다.<BL></BL>차근차근, 천천히
      </>
    ),
    tags: ["동기부여"],
    origin: {
      type: "영화",
      name: "인생 후르츠",
      url: "https://movie.naver.com/movie/bi/mi/basic.naver?code=179694",
    },
  },
  {
    sentence: (
      <>
        7,000RPM 어딘가엔 그런 지점이 있어.<BL></BL>모든 게 희미해지는 지점.
        차는 무게를 잃고 그대로 사라지지. 남은 건 시공을 가로지르는 몸뿐.
        <BL></BL>7,000 RPM.<BL></BL>바로 거기서 만나는 거야. 네게 다가오는
        느낌이 나. 귀에 바짝 붙어서 질문 하나를 던지지. 세상에서 가장 중요한
        질문.<BL></BL>
        "넌 누구인가?"
      </>
    ),
    tags: ["동기부여"],
    origin: {
      type: "영화",
      name: "포드 v 페라리",
      url: "https://movie.naver.com/movie/bi/mi/basic.naver?code=181710#",
    },
  },
];

export default SentenceArray;
