import loro from "../Image/Writer/loro.png";
import junwoo from "../Image/Writer/junwoo.png";

const WriterArray = [
  {
    profile: loro,
    name: "김로로",
    type: "월요작가",
    period: "2024.08.19 ~ 현재",
    introduce: (
      <>
        프리랜서 사진작가이자 독립출판 작가로 활동중인 김로로입니다.<p></p>주로
        자연의 따듯함과 고요함을 사진으로 담고 경험에 빗댄 글을 씁니다.<p></p>
        시선과 글이 하나 되는 표현을 쓰고, 남겨진 장면과 문장들을 오래 떠올리며
        곱씹는 일을 좋아합니다.
      </>
    ),
    channel: [
      { url: "https://www.instagram.com/loro__s/", type: "instagram" },
      { url: "https://lorokim.com/", type: "default" },
    ],
  },
  {
    profile: junwoo,
    name: "이준우",
    type: "화요작가",
    period: "2024.05.13 ~ 현재",
    introduce: (
      <>
        소설을 쓸 때 유독 엉덩이가 무거워지는 이준우입니다.
        <p></p>출판전야 지기(知己)를 겸하고 있습니다.
      </>
    ),
    channel: [
      { url: "https://www.instagram.com/jwluoe/", type: "instagram" },
      { url: "https://brunch.co.kr/brunchbook/cpjy", type: "brunch" },
    ],
  },
];

export default WriterArray;
