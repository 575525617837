import React from "react";
import styled from "styled-components";
import CardContainer from "./CardContainer";
import { BD, CN, OG, TG } from "./Utility";

const TBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 99.715%;
  background: ${(props) =>
    `rgba(0,0,0,1) url(${props.img}) no-repeat center center`};
  background-size: cover;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const MBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const Type = styled.div`
  display: flex;
  padding: 4px 6px;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 10px;
  margin-right: auto;
`;

const Writer = styled.div`
  font-size: 18px;
`;

const Period = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
`;

const IntroTitle = styled.div`
  display: flex;
  color: rgba(255, 255, 255, 0.3);
  font-size: 10px;
  margin-bottom: 6px;
`;

const IntroBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  /* border-left: 1px solid rgba(255, 255, 255, 0.3);
  padding-left: 3px; */
`;

const Intro = styled.span`
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-style: italic;
`;

const CardWriter = ({ profile, type, name, period, introduce, channel }) => {
  return (
    <CardContainer>
      {profile ? <TBox img={profile}></TBox> : null}
      <MBox>
        <Type>{type}</Type>
        <Writer>{name}</Writer>
        <Period>{period}</Period>
        <BD></BD>
        <IntroTitle>작가 소개</IntroTitle>
        <IntroBox>
          <Intro>{introduce}</Intro>
        </IntroBox>
      </MBox>
      <CN channels={channel}></CN>
    </CardContainer>
  );
};

export default CardWriter;
